var Ia = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
};
function Ra(e) {
  return e;
}
function W(e, t, r, n, a, s, i, o, l) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return function() {
        return t(e.apply(this, arguments));
      };
    case 3:
      return function() {
        return r(t(e.apply(this, arguments)));
      };
    case 4:
      return function() {
        return n(r(t(e.apply(this, arguments))));
      };
    case 5:
      return function() {
        return a(n(r(t(e.apply(this, arguments)))));
      };
    case 6:
      return function() {
        return s(a(n(r(t(e.apply(this, arguments))))));
      };
    case 7:
      return function() {
        return i(s(a(n(r(t(e.apply(this, arguments)))))));
      };
    case 8:
      return function() {
        return o(i(s(a(n(r(t(e.apply(this, arguments))))))));
      };
    case 9:
      return function() {
        return l(o(i(s(a(n(r(t(e.apply(this, arguments)))))))));
      };
  }
}
function g(e, t, r, n, a, s, i, o, l) {
  switch (arguments.length) {
    case 1:
      return e;
    case 2:
      return t(e);
    case 3:
      return r(t(e));
    case 4:
      return n(r(t(e)));
    case 5:
      return a(n(r(t(e))));
    case 6:
      return s(a(n(r(t(e)))));
    case 7:
      return i(s(a(n(r(t(e))))));
    case 8:
      return o(i(s(a(n(r(t(e)))))));
    case 9:
      return l(o(i(s(a(n(r(t(e))))))));
    default: {
      for (var c = arguments[0], h = 1; h < arguments.length; h++)
        c = arguments[h](c);
      return c;
    }
  }
}
var Na = function(e, t) {
  var r = typeof e == "number" ? function(n) {
    return n.length >= e;
  } : e;
  return function() {
    var n = Array.from(arguments);
    return r(arguments) ? t.apply(this, n) : function(a) {
      return t.apply(void 0, Ia([a], n, !1));
    };
  };
}, Pa = function(e) {
  return {
    equals: function(t, r) {
      return t === r || e(t, r);
    }
  };
}, La = function(e) {
  return Pa(function(t, r) {
    for (var n in e)
      if (!e[n].equals(t[n], r[n]))
        return !1;
    return !0;
  });
};
globalThis && globalThis.__spreadArray;
var vn = function(e) {
  return e._tag === "Some";
}, yn = { _tag: "None" }, gn = function(e) {
  return { _tag: "Some", value: e };
}, Za = function(e) {
  return e._tag === "Left";
}, Ma = function(e) {
  return e._tag === "Right";
}, Da = function(e) {
  return { _tag: "Left", left: e };
}, Va = function(e) {
  return { _tag: "Right", right: e };
}, Ua = function(e) {
  return [e];
}, qa = [], Ba = Object.prototype.hasOwnProperty, Ga = function(e) {
  return function(t) {
    return function(r) {
      return r.reduce(function(n, a) {
        return e.concat(n, a);
      }, t);
    };
  };
}, Ha = function(e) {
  return {
    concat: function() {
      return e;
    }
  };
}, za = function(e) {
  return {
    concat: function(t, r) {
      var n = {};
      for (var a in e)
        Ba.call(e, a) && (n[a] = e[a].concat(t[a], r[a]));
      return n;
    }
  };
}, It = function() {
  return { concat: Ra };
}, Fa = function() {
  return { concat: function(e, t) {
    return t;
  } };
}, Ka = Ga, Wa = Ha(void 0), $r = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
}, Qa = qa, Ja = function(e, t) {
  return e < 0 || e >= t.length;
}, Ya = function(e) {
  return function(t) {
    return $r($r([], t, !0), [e], !1);
  };
}, Xa = Ya, es = Ua, F = {
  equals: function(e, t) {
    return e === t;
  }
}, ts = {
  concat: function(e, t) {
    return e + t;
  }
}, rs = "", Qt = {
  concat: ts.concat,
  empty: rs
};
F.equals;
var mn = function(e) {
  return e.trim();
}, _n = function(e) {
  return e.length === 0;
}, Je = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function bn(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
function ns() {
  this.__data__ = [], this.size = 0;
}
var as = ns;
function ss(e, t) {
  return e === t || e !== e && t !== t;
}
var Jt = ss, is = Jt;
function os(e, t) {
  for (var r = e.length; r--; )
    if (is(e[r][0], t))
      return r;
  return -1;
}
var mt = os, us = mt, cs = Array.prototype, ls = cs.splice;
function ds(e) {
  var t = this.__data__, r = us(t, e);
  if (r < 0)
    return !1;
  var n = t.length - 1;
  return r == n ? t.pop() : ls.call(t, r, 1), --this.size, !0;
}
var fs = ds, ps = mt;
function hs(e) {
  var t = this.__data__, r = ps(t, e);
  return r < 0 ? void 0 : t[r][1];
}
var vs = hs, ys = mt;
function gs(e) {
  return ys(this.__data__, e) > -1;
}
var ms = gs, _s = mt;
function bs(e, t) {
  var r = this.__data__, n = _s(r, e);
  return n < 0 ? (++this.size, r.push([e, t])) : r[n][1] = t, this;
}
var Ts = bs, $s = as, ws = fs, xs = vs, As = ms, Ss = Ts;
function _e(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
_e.prototype.clear = $s;
_e.prototype.delete = ws;
_e.prototype.get = xs;
_e.prototype.has = As;
_e.prototype.set = Ss;
var _t = _e, Os = _t;
function Es() {
  this.__data__ = new Os(), this.size = 0;
}
var js = Es;
function ks(e) {
  var t = this.__data__, r = t.delete(e);
  return this.size = t.size, r;
}
var Cs = ks;
function Is(e) {
  return this.__data__.get(e);
}
var Rs = Is;
function Ns(e) {
  return this.__data__.has(e);
}
var Ps = Ns, Ls = typeof Je == "object" && Je && Je.Object === Object && Je, Tn = Ls, Zs = Tn, Ms = typeof self == "object" && self && self.Object === Object && self, Ds = Zs || Ms || Function("return this")(), G = Ds, Vs = G, Us = Vs.Symbol, bt = Us, wr = bt, $n = Object.prototype, qs = $n.hasOwnProperty, Bs = $n.toString, Ae = wr ? wr.toStringTag : void 0;
function Gs(e) {
  var t = qs.call(e, Ae), r = e[Ae];
  try {
    e[Ae] = void 0;
    var n = !0;
  } catch {
  }
  var a = Bs.call(e);
  return n && (t ? e[Ae] = r : delete e[Ae]), a;
}
var Hs = Gs, zs = Object.prototype, Fs = zs.toString;
function Ks(e) {
  return Fs.call(e);
}
var Ws = Ks, xr = bt, Qs = Hs, Js = Ws, Ys = "[object Null]", Xs = "[object Undefined]", Ar = xr ? xr.toStringTag : void 0;
function ei(e) {
  return e == null ? e === void 0 ? Xs : Ys : Ar && Ar in Object(e) ? Qs(e) : Js(e);
}
var Tt = ei;
function ti(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var De = ti, ri = Tt, ni = De, ai = "[object AsyncFunction]", si = "[object Function]", ii = "[object GeneratorFunction]", oi = "[object Proxy]";
function ui(e) {
  if (!ni(e))
    return !1;
  var t = ri(e);
  return t == si || t == ii || t == ai || t == oi;
}
var wn = ui, ci = G, li = ci["__core-js_shared__"], di = li, Rt = di, Sr = function() {
  var e = /[^.]+$/.exec(Rt && Rt.keys && Rt.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function fi(e) {
  return !!Sr && Sr in e;
}
var pi = fi, hi = Function.prototype, vi = hi.toString;
function yi(e) {
  if (e != null) {
    try {
      return vi.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var xn = yi, gi = wn, mi = pi, _i = De, bi = xn, Ti = /[\\^$.*+?()[\]{}|]/g, $i = /^\[object .+?Constructor\]$/, wi = Function.prototype, xi = Object.prototype, Ai = wi.toString, Si = xi.hasOwnProperty, Oi = RegExp(
  "^" + Ai.call(Si).replace(Ti, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Ei(e) {
  if (!_i(e) || mi(e))
    return !1;
  var t = gi(e) ? Oi : $i;
  return t.test(bi(e));
}
var ji = Ei;
function ki(e, t) {
  return e == null ? void 0 : e[t];
}
var Ci = ki, Ii = ji, Ri = Ci;
function Ni(e, t) {
  var r = Ri(e, t);
  return Ii(r) ? r : void 0;
}
var ue = Ni, Pi = ue, Li = G, Zi = Pi(Li, "Map"), Yt = Zi, Mi = ue, Di = Mi(Object, "create"), $t = Di, Or = $t;
function Vi() {
  this.__data__ = Or ? Or(null) : {}, this.size = 0;
}
var Ui = Vi;
function qi(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var Bi = qi, Gi = $t, Hi = "__lodash_hash_undefined__", zi = Object.prototype, Fi = zi.hasOwnProperty;
function Ki(e) {
  var t = this.__data__;
  if (Gi) {
    var r = t[e];
    return r === Hi ? void 0 : r;
  }
  return Fi.call(t, e) ? t[e] : void 0;
}
var Wi = Ki, Qi = $t, Ji = Object.prototype, Yi = Ji.hasOwnProperty;
function Xi(e) {
  var t = this.__data__;
  return Qi ? t[e] !== void 0 : Yi.call(t, e);
}
var eo = Xi, to = $t, ro = "__lodash_hash_undefined__";
function no(e, t) {
  var r = this.__data__;
  return this.size += this.has(e) ? 0 : 1, r[e] = to && t === void 0 ? ro : t, this;
}
var ao = no, so = Ui, io = Bi, oo = Wi, uo = eo, co = ao;
function be(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
be.prototype.clear = so;
be.prototype.delete = io;
be.prototype.get = oo;
be.prototype.has = uo;
be.prototype.set = co;
var lo = be, Er = lo, fo = _t, po = Yt;
function ho() {
  this.size = 0, this.__data__ = {
    hash: new Er(),
    map: new (po || fo)(),
    string: new Er()
  };
}
var vo = ho;
function yo(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
var go = yo, mo = go;
function _o(e, t) {
  var r = e.__data__;
  return mo(t) ? r[typeof t == "string" ? "string" : "hash"] : r.map;
}
var wt = _o, bo = wt;
function To(e) {
  var t = bo(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
var $o = To, wo = wt;
function xo(e) {
  return wo(this, e).get(e);
}
var Ao = xo, So = wt;
function Oo(e) {
  return So(this, e).has(e);
}
var Eo = Oo, jo = wt;
function ko(e, t) {
  var r = jo(this, e), n = r.size;
  return r.set(e, t), this.size += r.size == n ? 0 : 1, this;
}
var Co = ko, Io = vo, Ro = $o, No = Ao, Po = Eo, Lo = Co;
function Te(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.clear(); ++t < r; ) {
    var n = e[t];
    this.set(n[0], n[1]);
  }
}
Te.prototype.clear = Io;
Te.prototype.delete = Ro;
Te.prototype.get = No;
Te.prototype.has = Po;
Te.prototype.set = Lo;
var An = Te, Zo = _t, Mo = Yt, Do = An, Vo = 200;
function Uo(e, t) {
  var r = this.__data__;
  if (r instanceof Zo) {
    var n = r.__data__;
    if (!Mo || n.length < Vo - 1)
      return n.push([e, t]), this.size = ++r.size, this;
    r = this.__data__ = new Do(n);
  }
  return r.set(e, t), this.size = r.size, this;
}
var qo = Uo, Bo = _t, Go = js, Ho = Cs, zo = Rs, Fo = Ps, Ko = qo;
function $e(e) {
  var t = this.__data__ = new Bo(e);
  this.size = t.size;
}
$e.prototype.clear = Go;
$e.prototype.delete = Ho;
$e.prototype.get = zo;
$e.prototype.has = Fo;
$e.prototype.set = Ko;
var Sn = $e;
function Wo(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n && t(e[r], r, e) !== !1; )
    ;
  return e;
}
var Qo = Wo, Jo = ue, Yo = function() {
  try {
    var e = Jo(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), Xo = Yo, jr = Xo;
function eu(e, t, r) {
  t == "__proto__" && jr ? jr(e, t, {
    configurable: !0,
    enumerable: !0,
    value: r,
    writable: !0
  }) : e[t] = r;
}
var On = eu, tu = On, ru = Jt, nu = Object.prototype, au = nu.hasOwnProperty;
function su(e, t, r) {
  var n = e[t];
  (!(au.call(e, t) && ru(n, r)) || r === void 0 && !(t in e)) && tu(e, t, r);
}
var En = su, iu = En, ou = On;
function uu(e, t, r, n) {
  var a = !r;
  r || (r = {});
  for (var s = -1, i = t.length; ++s < i; ) {
    var o = t[s], l = n ? n(r[o], e[o], o, r, e) : void 0;
    l === void 0 && (l = e[o]), a ? ou(r, o, l) : iu(r, o, l);
  }
  return r;
}
var xt = uu;
function cu(e, t) {
  for (var r = -1, n = Array(e); ++r < e; )
    n[r] = t(r);
  return n;
}
var lu = cu;
function du(e) {
  return e != null && typeof e == "object";
}
var we = du, fu = Tt, pu = we, hu = "[object Arguments]";
function vu(e) {
  return pu(e) && fu(e) == hu;
}
var yu = vu, kr = yu, gu = we, jn = Object.prototype, mu = jn.hasOwnProperty, _u = jn.propertyIsEnumerable, bu = kr(function() {
  return arguments;
}()) ? kr : function(e) {
  return gu(e) && mu.call(e, "callee") && !_u.call(e, "callee");
}, Tu = bu, $u = Array.isArray, At = $u, rt = { exports: {} };
function wu() {
  return !1;
}
var xu = wu;
rt.exports;
(function(e, t) {
  var r = G, n = xu, a = t && !t.nodeType && t, s = a && !0 && e && !e.nodeType && e, i = s && s.exports === a, o = i ? r.Buffer : void 0, l = o ? o.isBuffer : void 0, c = l || n;
  e.exports = c;
})(rt, rt.exports);
var Xt = rt.exports, Au = 9007199254740991, Su = /^(?:0|[1-9]\d*)$/;
function Ou(e, t) {
  var r = typeof e;
  return t = t ?? Au, !!t && (r == "number" || r != "symbol" && Su.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
var Eu = Ou, ju = 9007199254740991;
function ku(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= ju;
}
var kn = ku, Cu = Tt, Iu = kn, Ru = we, Nu = "[object Arguments]", Pu = "[object Array]", Lu = "[object Boolean]", Zu = "[object Date]", Mu = "[object Error]", Du = "[object Function]", Vu = "[object Map]", Uu = "[object Number]", qu = "[object Object]", Bu = "[object RegExp]", Gu = "[object Set]", Hu = "[object String]", zu = "[object WeakMap]", Fu = "[object ArrayBuffer]", Ku = "[object DataView]", Wu = "[object Float32Array]", Qu = "[object Float64Array]", Ju = "[object Int8Array]", Yu = "[object Int16Array]", Xu = "[object Int32Array]", ec = "[object Uint8Array]", tc = "[object Uint8ClampedArray]", rc = "[object Uint16Array]", nc = "[object Uint32Array]", A = {};
A[Wu] = A[Qu] = A[Ju] = A[Yu] = A[Xu] = A[ec] = A[tc] = A[rc] = A[nc] = !0;
A[Nu] = A[Pu] = A[Fu] = A[Lu] = A[Ku] = A[Zu] = A[Mu] = A[Du] = A[Vu] = A[Uu] = A[qu] = A[Bu] = A[Gu] = A[Hu] = A[zu] = !1;
function ac(e) {
  return Ru(e) && Iu(e.length) && !!A[Cu(e)];
}
var sc = ac;
function ic(e) {
  return function(t) {
    return e(t);
  };
}
var er = ic, nt = { exports: {} };
nt.exports;
(function(e, t) {
  var r = Tn, n = t && !t.nodeType && t, a = n && !0 && e && !e.nodeType && e, s = a && a.exports === n, i = s && r.process, o = function() {
    try {
      var l = a && a.require && a.require("util").types;
      return l || i && i.binding && i.binding("util");
    } catch {
    }
  }();
  e.exports = o;
})(nt, nt.exports);
var tr = nt.exports, oc = sc, uc = er, Cr = tr, Ir = Cr && Cr.isTypedArray, cc = Ir ? uc(Ir) : oc, Cn = cc, lc = lu, dc = Tu, fc = At, pc = Xt, hc = Eu, vc = Cn, yc = Object.prototype, gc = yc.hasOwnProperty;
function mc(e, t) {
  var r = fc(e), n = !r && dc(e), a = !r && !n && pc(e), s = !r && !n && !a && vc(e), i = r || n || a || s, o = i ? lc(e.length, String) : [], l = o.length;
  for (var c in e)
    (t || gc.call(e, c)) && !(i && // Safari 9 has enumerable `arguments.length` in strict mode.
    (c == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    a && (c == "offset" || c == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    s && (c == "buffer" || c == "byteLength" || c == "byteOffset") || // Skip index properties.
    hc(c, l))) && o.push(c);
  return o;
}
var In = mc, _c = Object.prototype;
function bc(e) {
  var t = e && e.constructor, r = typeof t == "function" && t.prototype || _c;
  return e === r;
}
var rr = bc;
function Tc(e, t) {
  return function(r) {
    return e(t(r));
  };
}
var Rn = Tc, $c = Rn, wc = $c(Object.keys, Object), xc = wc, Ac = rr, Sc = xc, Oc = Object.prototype, Ec = Oc.hasOwnProperty;
function jc(e) {
  if (!Ac(e))
    return Sc(e);
  var t = [];
  for (var r in Object(e))
    Ec.call(e, r) && r != "constructor" && t.push(r);
  return t;
}
var kc = jc, Cc = wn, Ic = kn;
function Rc(e) {
  return e != null && Ic(e.length) && !Cc(e);
}
var Nn = Rc, Nc = In, Pc = kc, Lc = Nn;
function Zc(e) {
  return Lc(e) ? Nc(e) : Pc(e);
}
var nr = Zc, Mc = xt, Dc = nr;
function Vc(e, t) {
  return e && Mc(t, Dc(t), e);
}
var Uc = Vc;
function qc(e) {
  var t = [];
  if (e != null)
    for (var r in Object(e))
      t.push(r);
  return t;
}
var Bc = qc, Gc = De, Hc = rr, zc = Bc, Fc = Object.prototype, Kc = Fc.hasOwnProperty;
function Wc(e) {
  if (!Gc(e))
    return zc(e);
  var t = Hc(e), r = [];
  for (var n in e)
    n == "constructor" && (t || !Kc.call(e, n)) || r.push(n);
  return r;
}
var Qc = Wc, Jc = In, Yc = Qc, Xc = Nn;
function el(e) {
  return Xc(e) ? Jc(e, !0) : Yc(e);
}
var ar = el, tl = xt, rl = ar;
function nl(e, t) {
  return e && tl(t, rl(t), e);
}
var al = nl, at = { exports: {} };
at.exports;
(function(e, t) {
  var r = G, n = t && !t.nodeType && t, a = n && !0 && e && !e.nodeType && e, s = a && a.exports === n, i = s ? r.Buffer : void 0, o = i ? i.allocUnsafe : void 0;
  function l(c, h) {
    if (h)
      return c.slice();
    var T = c.length, w = o ? o(T) : new c.constructor(T);
    return c.copy(w), w;
  }
  e.exports = l;
})(at, at.exports);
var sl = at.exports;
function il(e, t) {
  var r = -1, n = e.length;
  for (t || (t = Array(n)); ++r < n; )
    t[r] = e[r];
  return t;
}
var ol = il;
function ul(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length, a = 0, s = []; ++r < n; ) {
    var i = e[r];
    t(i, r, e) && (s[a++] = i);
  }
  return s;
}
var cl = ul;
function ll() {
  return [];
}
var Pn = ll, dl = cl, fl = Pn, pl = Object.prototype, hl = pl.propertyIsEnumerable, Rr = Object.getOwnPropertySymbols, vl = Rr ? function(e) {
  return e == null ? [] : (e = Object(e), dl(Rr(e), function(t) {
    return hl.call(e, t);
  }));
} : fl, sr = vl, yl = xt, gl = sr;
function ml(e, t) {
  return yl(e, gl(e), t);
}
var _l = ml;
function bl(e, t) {
  for (var r = -1, n = t.length, a = e.length; ++r < n; )
    e[a + r] = t[r];
  return e;
}
var Ln = bl, Tl = Rn, $l = Tl(Object.getPrototypeOf, Object), Zn = $l, wl = Ln, xl = Zn, Al = sr, Sl = Pn, Ol = Object.getOwnPropertySymbols, El = Ol ? function(e) {
  for (var t = []; e; )
    wl(t, Al(e)), e = xl(e);
  return t;
} : Sl, Mn = El, jl = xt, kl = Mn;
function Cl(e, t) {
  return jl(e, kl(e), t);
}
var Il = Cl, Rl = Ln, Nl = At;
function Pl(e, t, r) {
  var n = t(e);
  return Nl(e) ? n : Rl(n, r(e));
}
var Dn = Pl, Ll = Dn, Zl = sr, Ml = nr;
function Dl(e) {
  return Ll(e, Ml, Zl);
}
var Vn = Dl, Vl = Dn, Ul = Mn, ql = ar;
function Bl(e) {
  return Vl(e, ql, Ul);
}
var Gl = Bl, Hl = ue, zl = G, Fl = Hl(zl, "DataView"), Kl = Fl, Wl = ue, Ql = G, Jl = Wl(Ql, "Promise"), Yl = Jl, Xl = ue, ed = G, td = Xl(ed, "Set"), rd = td, nd = ue, ad = G, sd = nd(ad, "WeakMap"), id = sd, Zt = Kl, Mt = Yt, Dt = Yl, Vt = rd, Ut = id, Un = Tt, xe = xn, Nr = "[object Map]", od = "[object Object]", Pr = "[object Promise]", Lr = "[object Set]", Zr = "[object WeakMap]", Mr = "[object DataView]", ud = xe(Zt), cd = xe(Mt), ld = xe(Dt), dd = xe(Vt), fd = xe(Ut), ne = Un;
(Zt && ne(new Zt(new ArrayBuffer(1))) != Mr || Mt && ne(new Mt()) != Nr || Dt && ne(Dt.resolve()) != Pr || Vt && ne(new Vt()) != Lr || Ut && ne(new Ut()) != Zr) && (ne = function(e) {
  var t = Un(e), r = t == od ? e.constructor : void 0, n = r ? xe(r) : "";
  if (n)
    switch (n) {
      case ud:
        return Mr;
      case cd:
        return Nr;
      case ld:
        return Pr;
      case dd:
        return Lr;
      case fd:
        return Zr;
    }
  return t;
});
var St = ne, pd = Object.prototype, hd = pd.hasOwnProperty;
function vd(e) {
  var t = e.length, r = new e.constructor(t);
  return t && typeof e[0] == "string" && hd.call(e, "index") && (r.index = e.index, r.input = e.input), r;
}
var yd = vd, gd = G, md = gd.Uint8Array, qn = md, Dr = qn;
function _d(e) {
  var t = new e.constructor(e.byteLength);
  return new Dr(t).set(new Dr(e)), t;
}
var ir = _d, bd = ir;
function Td(e, t) {
  var r = t ? bd(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.byteLength);
}
var $d = Td, wd = /\w*$/;
function xd(e) {
  var t = new e.constructor(e.source, wd.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var Ad = xd, Vr = bt, Ur = Vr ? Vr.prototype : void 0, qr = Ur ? Ur.valueOf : void 0;
function Sd(e) {
  return qr ? Object(qr.call(e)) : {};
}
var Od = Sd, Ed = ir;
function jd(e, t) {
  var r = t ? Ed(e.buffer) : e.buffer;
  return new e.constructor(r, e.byteOffset, e.length);
}
var kd = jd, Cd = ir, Id = $d, Rd = Ad, Nd = Od, Pd = kd, Ld = "[object Boolean]", Zd = "[object Date]", Md = "[object Map]", Dd = "[object Number]", Vd = "[object RegExp]", Ud = "[object Set]", qd = "[object String]", Bd = "[object Symbol]", Gd = "[object ArrayBuffer]", Hd = "[object DataView]", zd = "[object Float32Array]", Fd = "[object Float64Array]", Kd = "[object Int8Array]", Wd = "[object Int16Array]", Qd = "[object Int32Array]", Jd = "[object Uint8Array]", Yd = "[object Uint8ClampedArray]", Xd = "[object Uint16Array]", ef = "[object Uint32Array]";
function tf(e, t, r) {
  var n = e.constructor;
  switch (t) {
    case Gd:
      return Cd(e);
    case Ld:
    case Zd:
      return new n(+e);
    case Hd:
      return Id(e, r);
    case zd:
    case Fd:
    case Kd:
    case Wd:
    case Qd:
    case Jd:
    case Yd:
    case Xd:
    case ef:
      return Pd(e, r);
    case Md:
      return new n();
    case Dd:
    case qd:
      return new n(e);
    case Vd:
      return Rd(e);
    case Ud:
      return new n();
    case Bd:
      return Nd(e);
  }
}
var rf = tf, nf = De, Br = Object.create, af = function() {
  function e() {
  }
  return function(t) {
    if (!nf(t))
      return {};
    if (Br)
      return Br(t);
    e.prototype = t;
    var r = new e();
    return e.prototype = void 0, r;
  };
}(), sf = af, of = sf, uf = Zn, cf = rr;
function lf(e) {
  return typeof e.constructor == "function" && !cf(e) ? of(uf(e)) : {};
}
var df = lf, ff = St, pf = we, hf = "[object Map]";
function vf(e) {
  return pf(e) && ff(e) == hf;
}
var yf = vf, gf = yf, mf = er, Gr = tr, Hr = Gr && Gr.isMap, _f = Hr ? mf(Hr) : gf, bf = _f, Tf = St, $f = we, wf = "[object Set]";
function xf(e) {
  return $f(e) && Tf(e) == wf;
}
var Af = xf, Sf = Af, Of = er, zr = tr, Fr = zr && zr.isSet, Ef = Fr ? Of(Fr) : Sf, jf = Ef, kf = Sn, Cf = Qo, If = En, Rf = Uc, Nf = al, Pf = sl, Lf = ol, Zf = _l, Mf = Il, Df = Vn, Vf = Gl, Uf = St, qf = yd, Bf = rf, Gf = df, Hf = At, zf = Xt, Ff = bf, Kf = De, Wf = jf, Qf = nr, Jf = ar, Yf = 1, Xf = 2, ep = 4, Bn = "[object Arguments]", tp = "[object Array]", rp = "[object Boolean]", np = "[object Date]", ap = "[object Error]", Gn = "[object Function]", sp = "[object GeneratorFunction]", ip = "[object Map]", op = "[object Number]", Hn = "[object Object]", up = "[object RegExp]", cp = "[object Set]", lp = "[object String]", dp = "[object Symbol]", fp = "[object WeakMap]", pp = "[object ArrayBuffer]", hp = "[object DataView]", vp = "[object Float32Array]", yp = "[object Float64Array]", gp = "[object Int8Array]", mp = "[object Int16Array]", _p = "[object Int32Array]", bp = "[object Uint8Array]", Tp = "[object Uint8ClampedArray]", $p = "[object Uint16Array]", wp = "[object Uint32Array]", x = {};
x[Bn] = x[tp] = x[pp] = x[hp] = x[rp] = x[np] = x[vp] = x[yp] = x[gp] = x[mp] = x[_p] = x[ip] = x[op] = x[Hn] = x[up] = x[cp] = x[lp] = x[dp] = x[bp] = x[Tp] = x[$p] = x[wp] = !0;
x[ap] = x[Gn] = x[fp] = !1;
function et(e, t, r, n, a, s) {
  var i, o = t & Yf, l = t & Xf, c = t & ep;
  if (r && (i = a ? r(e, n, a, s) : r(e)), i !== void 0)
    return i;
  if (!Kf(e))
    return e;
  var h = Hf(e);
  if (h) {
    if (i = qf(e), !o)
      return Lf(e, i);
  } else {
    var T = Uf(e), w = T == Gn || T == sp;
    if (zf(e))
      return Pf(e, o);
    if (T == Hn || T == Bn || w && !a) {
      if (i = l || w ? {} : Gf(e), !o)
        return l ? Mf(e, Nf(i, e)) : Zf(e, Rf(i, e));
    } else {
      if (!x[T])
        return a ? e : {};
      i = Bf(e, T, o);
    }
  }
  s || (s = new kf());
  var C = s.get(e);
  if (C)
    return C;
  s.set(e, i), Wf(e) ? e.forEach(function(k) {
    i.add(et(k, t, r, k, e, s));
  }) : Ff(e) && e.forEach(function(k, R) {
    i.set(R, et(k, t, r, R, e, s));
  });
  var P = c ? l ? Vf : Df : l ? Jf : Qf, L = h ? void 0 : P(e);
  return Cf(L || e, function(k, R) {
    L && (R = k, k = e[R]), If(i, R, et(k, t, r, R, e, s));
  }), i;
}
var xp = et, Ap = xp, Sp = 1, Op = 4;
function Ep(e) {
  return Ap(e, Sp | Op);
}
var jp = Ep;
const kp = /* @__PURE__ */ bn(jp);
var Ve = (e) => e, Cp = class {
  /**
   * @package
   */
  constructor(e, t, r) {
    this.versionMap = e, this.latestVersion = t, this.getVersion = r;
  }
  /**
   * Returns whether the given data is a valid entity of any version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of any version of the entity.
   */
  is(e) {
    let t = this.getVersion(e);
    if (t === null)
      return !1;
    const r = this.versionMap[t];
    return r ? r.schema.safeParse(e).success : !1;
  }
  /**
   * Returns whether the given data is a valid entity of the latest version of the entity.
   * @param data The data to check
   * @returns Whether the given data is a valid entity of the latest version of the entity.
   */
  isLatest(e) {
    return this.versionMap[this.latestVersion].schema.safeParse(e).success;
  }
  /**
   * Similar to Zod's `safeParse` method, but also migrates the data to the latest version.
   * @param data The data to parse
   * @returns The result from parsing data, if successful, older versions are migrated to the latest version
   */
  safeParse(e) {
    const t = this.getVersion(e);
    if (t === null)
      return { type: "err", error: { type: "VER_CHECK_FAIL" } };
    const r = this.versionMap[t];
    if (!r)
      return { type: "err", error: { type: "INVALID_VER" } };
    const n = r.schema.safeParse(e);
    if (!n.success)
      return { type: "err", error: { type: "GIVEN_VER_VALIDATION_FAIL", version: t, versionDef: r, error: n.error } };
    let a = e;
    for (let s = t + 1; s <= this.latestVersion; s++) {
      const i = this.versionMap[s];
      if (!i)
        return { type: "err", error: { type: "BUG_NO_INTERMEDIATE_FOUND", missingVer: s } };
      if (i.initial)
        return { type: "err", error: { type: "BUG_INTERMEDIATE_MARKED_INITIAL", ver: s } };
      a = i.up(a);
    }
    return { type: "ok", value: a };
  }
};
function or(e) {
  return new Cp(e.versionMap, e.latestVersion, e.getVersion);
}
var $;
(function(e) {
  e.assertEqual = (a) => a;
  function t(a) {
  }
  e.assertIs = t;
  function r(a) {
    throw new Error();
  }
  e.assertNever = r, e.arrayToEnum = (a) => {
    const s = {};
    for (const i of a)
      s[i] = i;
    return s;
  }, e.getValidEnumValues = (a) => {
    const s = e.objectKeys(a).filter((o) => typeof a[a[o]] != "number"), i = {};
    for (const o of s)
      i[o] = a[o];
    return e.objectValues(i);
  }, e.objectValues = (a) => e.objectKeys(a).map(function(s) {
    return a[s];
  }), e.objectKeys = typeof Object.keys == "function" ? (a) => Object.keys(a) : (a) => {
    const s = [];
    for (const i in a)
      Object.prototype.hasOwnProperty.call(a, i) && s.push(i);
    return s;
  }, e.find = (a, s) => {
    for (const i of a)
      if (s(i))
        return i;
  }, e.isInteger = typeof Number.isInteger == "function" ? (a) => Number.isInteger(a) : (a) => typeof a == "number" && isFinite(a) && Math.floor(a) === a;
  function n(a, s = " | ") {
    return a.map((i) => typeof i == "string" ? `'${i}'` : i).join(s);
  }
  e.joinValues = n, e.jsonStringifyReplacer = (a, s) => typeof s == "bigint" ? s.toString() : s;
})($ || ($ = {}));
var qt;
(function(e) {
  e.mergeShapes = (t, r) => ({
    ...t,
    ...r
    // second overwrites first
  });
})(qt || (qt = {}));
const f = $.arrayToEnum([
  "string",
  "nan",
  "number",
  "integer",
  "float",
  "boolean",
  "date",
  "bigint",
  "symbol",
  "function",
  "undefined",
  "null",
  "array",
  "object",
  "unknown",
  "promise",
  "void",
  "never",
  "map",
  "set"
]), K = (e) => {
  switch (typeof e) {
    case "undefined":
      return f.undefined;
    case "string":
      return f.string;
    case "number":
      return isNaN(e) ? f.nan : f.number;
    case "boolean":
      return f.boolean;
    case "function":
      return f.function;
    case "bigint":
      return f.bigint;
    case "symbol":
      return f.symbol;
    case "object":
      return Array.isArray(e) ? f.array : e === null ? f.null : e.then && typeof e.then == "function" && e.catch && typeof e.catch == "function" ? f.promise : typeof Map < "u" && e instanceof Map ? f.map : typeof Set < "u" && e instanceof Set ? f.set : typeof Date < "u" && e instanceof Date ? f.date : f.object;
    default:
      return f.unknown;
  }
}, d = $.arrayToEnum([
  "invalid_type",
  "invalid_literal",
  "custom",
  "invalid_union",
  "invalid_union_discriminator",
  "invalid_enum_value",
  "unrecognized_keys",
  "invalid_arguments",
  "invalid_return_type",
  "invalid_date",
  "invalid_string",
  "too_small",
  "too_big",
  "invalid_intersection_types",
  "not_multiple_of",
  "not_finite"
]), Ip = (e) => JSON.stringify(e, null, 2).replace(/"([^"]+)":/g, "$1:");
class M extends Error {
  constructor(t) {
    super(), this.issues = [], this.addIssue = (n) => {
      this.issues = [...this.issues, n];
    }, this.addIssues = (n = []) => {
      this.issues = [...this.issues, ...n];
    };
    const r = new.target.prototype;
    Object.setPrototypeOf ? Object.setPrototypeOf(this, r) : this.__proto__ = r, this.name = "ZodError", this.issues = t;
  }
  get errors() {
    return this.issues;
  }
  format(t) {
    const r = t || function(s) {
      return s.message;
    }, n = { _errors: [] }, a = (s) => {
      for (const i of s.issues)
        if (i.code === "invalid_union")
          i.unionErrors.map(a);
        else if (i.code === "invalid_return_type")
          a(i.returnTypeError);
        else if (i.code === "invalid_arguments")
          a(i.argumentsError);
        else if (i.path.length === 0)
          n._errors.push(r(i));
        else {
          let o = n, l = 0;
          for (; l < i.path.length; ) {
            const c = i.path[l];
            l === i.path.length - 1 ? (o[c] = o[c] || { _errors: [] }, o[c]._errors.push(r(i))) : o[c] = o[c] || { _errors: [] }, o = o[c], l++;
          }
        }
    };
    return a(this), n;
  }
  toString() {
    return this.message;
  }
  get message() {
    return JSON.stringify(this.issues, $.jsonStringifyReplacer, 2);
  }
  get isEmpty() {
    return this.issues.length === 0;
  }
  flatten(t = (r) => r.message) {
    const r = {}, n = [];
    for (const a of this.issues)
      a.path.length > 0 ? (r[a.path[0]] = r[a.path[0]] || [], r[a.path[0]].push(t(a))) : n.push(t(a));
    return { formErrors: n, fieldErrors: r };
  }
  get formErrors() {
    return this.flatten();
  }
}
M.create = (e) => new M(e);
const Se = (e, t) => {
  let r;
  switch (e.code) {
    case d.invalid_type:
      e.received === f.undefined ? r = "Required" : r = `Expected ${e.expected}, received ${e.received}`;
      break;
    case d.invalid_literal:
      r = `Invalid literal value, expected ${JSON.stringify(e.expected, $.jsonStringifyReplacer)}`;
      break;
    case d.unrecognized_keys:
      r = `Unrecognized key(s) in object: ${$.joinValues(e.keys, ", ")}`;
      break;
    case d.invalid_union:
      r = "Invalid input";
      break;
    case d.invalid_union_discriminator:
      r = `Invalid discriminator value. Expected ${$.joinValues(e.options)}`;
      break;
    case d.invalid_enum_value:
      r = `Invalid enum value. Expected ${$.joinValues(e.options)}, received '${e.received}'`;
      break;
    case d.invalid_arguments:
      r = "Invalid function arguments";
      break;
    case d.invalid_return_type:
      r = "Invalid function return type";
      break;
    case d.invalid_date:
      r = "Invalid date";
      break;
    case d.invalid_string:
      typeof e.validation == "object" ? "includes" in e.validation ? (r = `Invalid input: must include "${e.validation.includes}"`, typeof e.validation.position == "number" && (r = `${r} at one or more positions greater than or equal to ${e.validation.position}`)) : "startsWith" in e.validation ? r = `Invalid input: must start with "${e.validation.startsWith}"` : "endsWith" in e.validation ? r = `Invalid input: must end with "${e.validation.endsWith}"` : $.assertNever(e.validation) : e.validation !== "regex" ? r = `Invalid ${e.validation}` : r = "Invalid";
      break;
    case d.too_small:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "more than"} ${e.minimum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at least" : "over"} ${e.minimum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${e.minimum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly equal to " : e.inclusive ? "greater than or equal to " : "greater than "}${new Date(Number(e.minimum))}` : r = "Invalid input";
      break;
    case d.too_big:
      e.type === "array" ? r = `Array must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "less than"} ${e.maximum} element(s)` : e.type === "string" ? r = `String must contain ${e.exact ? "exactly" : e.inclusive ? "at most" : "under"} ${e.maximum} character(s)` : e.type === "number" ? r = `Number must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "bigint" ? r = `BigInt must be ${e.exact ? "exactly" : e.inclusive ? "less than or equal to" : "less than"} ${e.maximum}` : e.type === "date" ? r = `Date must be ${e.exact ? "exactly" : e.inclusive ? "smaller than or equal to" : "smaller than"} ${new Date(Number(e.maximum))}` : r = "Invalid input";
      break;
    case d.custom:
      r = "Invalid input";
      break;
    case d.invalid_intersection_types:
      r = "Intersection results could not be merged";
      break;
    case d.not_multiple_of:
      r = `Number must be a multiple of ${e.multipleOf}`;
      break;
    case d.not_finite:
      r = "Number must be finite";
      break;
    default:
      r = t.defaultError, $.assertNever(e);
  }
  return { message: r };
};
let zn = Se;
function Rp(e) {
  zn = e;
}
function st() {
  return zn;
}
const it = (e) => {
  const { data: t, path: r, errorMaps: n, issueData: a } = e, s = [...r, ...a.path || []], i = {
    ...a,
    path: s
  };
  let o = "";
  const l = n.filter((c) => !!c).slice().reverse();
  for (const c of l)
    o = c(i, { data: t, defaultError: o }).message;
  return {
    ...a,
    path: s,
    message: a.message || o
  };
}, Np = [];
function p(e, t) {
  const r = it({
    issueData: t,
    data: e.data,
    path: e.path,
    errorMaps: [
      e.common.contextualErrorMap,
      e.schemaErrorMap,
      st(),
      Se
      // then global default map
    ].filter((n) => !!n)
  });
  e.common.issues.push(r);
}
class I {
  constructor() {
    this.value = "valid";
  }
  dirty() {
    this.value === "valid" && (this.value = "dirty");
  }
  abort() {
    this.value !== "aborted" && (this.value = "aborted");
  }
  static mergeArray(t, r) {
    const n = [];
    for (const a of r) {
      if (a.status === "aborted")
        return m;
      a.status === "dirty" && t.dirty(), n.push(a.value);
    }
    return { status: t.value, value: n };
  }
  static async mergeObjectAsync(t, r) {
    const n = [];
    for (const a of r)
      n.push({
        key: await a.key,
        value: await a.value
      });
    return I.mergeObjectSync(t, n);
  }
  static mergeObjectSync(t, r) {
    const n = {};
    for (const a of r) {
      const { key: s, value: i } = a;
      if (s.status === "aborted" || i.status === "aborted")
        return m;
      s.status === "dirty" && t.dirty(), i.status === "dirty" && t.dirty(), s.value !== "__proto__" && (typeof i.value < "u" || a.alwaysSet) && (n[s.value] = i.value);
    }
    return { status: t.value, value: n };
  }
}
const m = Object.freeze({
  status: "aborted"
}), Fn = (e) => ({ status: "dirty", value: e }), N = (e) => ({ status: "valid", value: e }), Bt = (e) => e.status === "aborted", Gt = (e) => e.status === "dirty", Oe = (e) => e.status === "valid", ot = (e) => typeof Promise < "u" && e instanceof Promise;
var v;
(function(e) {
  e.errToObj = (t) => typeof t == "string" ? { message: t } : t || {}, e.toString = (t) => typeof t == "string" ? t : t == null ? void 0 : t.message;
})(v || (v = {}));
class U {
  constructor(t, r, n, a) {
    this._cachedPath = [], this.parent = t, this.data = r, this._path = n, this._key = a;
  }
  get path() {
    return this._cachedPath.length || (this._key instanceof Array ? this._cachedPath.push(...this._path, ...this._key) : this._cachedPath.push(...this._path, this._key)), this._cachedPath;
  }
}
const Kr = (e, t) => {
  if (Oe(t))
    return { success: !0, data: t.value };
  if (!e.common.issues.length)
    throw new Error("Validation failed but no issues detected.");
  return {
    success: !1,
    get error() {
      if (this._error)
        return this._error;
      const r = new M(e.common.issues);
      return this._error = r, this._error;
    }
  };
};
function _(e) {
  if (!e)
    return {};
  const { errorMap: t, invalid_type_error: r, required_error: n, description: a } = e;
  if (t && (r || n))
    throw new Error(`Can't use "invalid_type_error" or "required_error" in conjunction with custom error map.`);
  return t ? { errorMap: t, description: a } : { errorMap: (i, o) => i.code !== "invalid_type" ? { message: o.defaultError } : typeof o.data > "u" ? { message: n ?? o.defaultError } : { message: r ?? o.defaultError }, description: a };
}
class b {
  constructor(t) {
    this.spa = this.safeParseAsync, this._def = t, this.parse = this.parse.bind(this), this.safeParse = this.safeParse.bind(this), this.parseAsync = this.parseAsync.bind(this), this.safeParseAsync = this.safeParseAsync.bind(this), this.spa = this.spa.bind(this), this.refine = this.refine.bind(this), this.refinement = this.refinement.bind(this), this.superRefine = this.superRefine.bind(this), this.optional = this.optional.bind(this), this.nullable = this.nullable.bind(this), this.nullish = this.nullish.bind(this), this.array = this.array.bind(this), this.promise = this.promise.bind(this), this.or = this.or.bind(this), this.and = this.and.bind(this), this.transform = this.transform.bind(this), this.brand = this.brand.bind(this), this.default = this.default.bind(this), this.catch = this.catch.bind(this), this.describe = this.describe.bind(this), this.pipe = this.pipe.bind(this), this.readonly = this.readonly.bind(this), this.isNullable = this.isNullable.bind(this), this.isOptional = this.isOptional.bind(this);
  }
  get description() {
    return this._def.description;
  }
  _getType(t) {
    return K(t.data);
  }
  _getOrReturnCtx(t, r) {
    return r || {
      common: t.parent.common,
      data: t.data,
      parsedType: K(t.data),
      schemaErrorMap: this._def.errorMap,
      path: t.path,
      parent: t.parent
    };
  }
  _processInputParams(t) {
    return {
      status: new I(),
      ctx: {
        common: t.parent.common,
        data: t.data,
        parsedType: K(t.data),
        schemaErrorMap: this._def.errorMap,
        path: t.path,
        parent: t.parent
      }
    };
  }
  _parseSync(t) {
    const r = this._parse(t);
    if (ot(r))
      throw new Error("Synchronous parse encountered promise.");
    return r;
  }
  _parseAsync(t) {
    const r = this._parse(t);
    return Promise.resolve(r);
  }
  parse(t, r) {
    const n = this.safeParse(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  safeParse(t, r) {
    var n;
    const a = {
      common: {
        issues: [],
        async: (n = r == null ? void 0 : r.async) !== null && n !== void 0 ? n : !1,
        contextualErrorMap: r == null ? void 0 : r.errorMap
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: K(t)
    }, s = this._parseSync({ data: t, path: a.path, parent: a });
    return Kr(a, s);
  }
  async parseAsync(t, r) {
    const n = await this.safeParseAsync(t, r);
    if (n.success)
      return n.data;
    throw n.error;
  }
  async safeParseAsync(t, r) {
    const n = {
      common: {
        issues: [],
        contextualErrorMap: r == null ? void 0 : r.errorMap,
        async: !0
      },
      path: (r == null ? void 0 : r.path) || [],
      schemaErrorMap: this._def.errorMap,
      parent: null,
      data: t,
      parsedType: K(t)
    }, a = this._parse({ data: t, path: n.path, parent: n }), s = await (ot(a) ? a : Promise.resolve(a));
    return Kr(n, s);
  }
  refine(t, r) {
    const n = (a) => typeof r == "string" || typeof r > "u" ? { message: r } : typeof r == "function" ? r(a) : r;
    return this._refinement((a, s) => {
      const i = t(a), o = () => s.addIssue({
        code: d.custom,
        ...n(a)
      });
      return typeof Promise < "u" && i instanceof Promise ? i.then((l) => l ? !0 : (o(), !1)) : i ? !0 : (o(), !1);
    });
  }
  refinement(t, r) {
    return this._refinement((n, a) => t(n) ? !0 : (a.addIssue(typeof r == "function" ? r(n, a) : r), !1));
  }
  _refinement(t) {
    return new V({
      schema: this,
      typeName: y.ZodEffects,
      effect: { type: "refinement", refinement: t }
    });
  }
  superRefine(t) {
    return this._refinement(t);
  }
  optional() {
    return H.create(this, this._def);
  }
  nullable() {
    return oe.create(this, this._def);
  }
  nullish() {
    return this.nullable().optional();
  }
  array() {
    return D.create(this, this._def);
  }
  promise() {
    return he.create(this, this._def);
  }
  or(t) {
    return Ce.create([this, t], this._def);
  }
  and(t) {
    return Ie.create(this, t, this._def);
  }
  transform(t) {
    return new V({
      ..._(this._def),
      schema: this,
      typeName: y.ZodEffects,
      effect: { type: "transform", transform: t }
    });
  }
  default(t) {
    const r = typeof t == "function" ? t : () => t;
    return new Ze({
      ..._(this._def),
      innerType: this,
      defaultValue: r,
      typeName: y.ZodDefault
    });
  }
  brand() {
    return new Wn({
      typeName: y.ZodBranded,
      type: this,
      ..._(this._def)
    });
  }
  catch(t) {
    const r = typeof t == "function" ? t : () => t;
    return new dt({
      ..._(this._def),
      innerType: this,
      catchValue: r,
      typeName: y.ZodCatch
    });
  }
  describe(t) {
    const r = this.constructor;
    return new r({
      ...this._def,
      description: t
    });
  }
  pipe(t) {
    return Ue.create(this, t);
  }
  readonly() {
    return pt.create(this);
  }
  isOptional() {
    return this.safeParse(void 0).success;
  }
  isNullable() {
    return this.safeParse(null).success;
  }
}
const Pp = /^c[^\s-]{8,}$/i, Lp = /^[a-z][a-z0-9]*$/, Zp = /^[0-9A-HJKMNP-TV-Z]{26}$/, Mp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i, Dp = /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i, Vp = "^(\\p{Extended_Pictographic}|\\p{Emoji_Component})+$";
let Nt;
const Up = /^(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))$/, qp = /^(([a-f0-9]{1,4}:){7}|::([a-f0-9]{1,4}:){0,6}|([a-f0-9]{1,4}:){1}:([a-f0-9]{1,4}:){0,5}|([a-f0-9]{1,4}:){2}:([a-f0-9]{1,4}:){0,4}|([a-f0-9]{1,4}:){3}:([a-f0-9]{1,4}:){0,3}|([a-f0-9]{1,4}:){4}:([a-f0-9]{1,4}:){0,2}|([a-f0-9]{1,4}:){5}:([a-f0-9]{1,4}:){0,1})([a-f0-9]{1,4}|(((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2}))\.){3}((25[0-5])|(2[0-4][0-9])|(1[0-9]{2})|([0-9]{1,2})))$/, Bp = (e) => e.precision ? e.offset ? new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${e.precision}}(([+-]\\d{2}(:?\\d{2})?)|Z)$`) : new RegExp(`^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{${e.precision}}Z$`) : e.precision === 0 ? e.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}Z$") : e.offset ? new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?(([+-]\\d{2}(:?\\d{2})?)|Z)$") : new RegExp("^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}(\\.\\d+)?Z$");
function Gp(e, t) {
  return !!((t === "v4" || !t) && Up.test(e) || (t === "v6" || !t) && qp.test(e));
}
class Z extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = String(t.data)), this._getType(t) !== f.string) {
      const s = this._getOrReturnCtx(t);
      return p(
        s,
        {
          code: d.invalid_type,
          expected: f.string,
          received: s.parsedType
        }
        //
      ), m;
    }
    const n = new I();
    let a;
    for (const s of this._def.checks)
      if (s.kind === "min")
        t.data.length < s.value && (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.too_small,
          minimum: s.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: s.message
        }), n.dirty());
      else if (s.kind === "max")
        t.data.length > s.value && (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.too_big,
          maximum: s.value,
          type: "string",
          inclusive: !0,
          exact: !1,
          message: s.message
        }), n.dirty());
      else if (s.kind === "length") {
        const i = t.data.length > s.value, o = t.data.length < s.value;
        (i || o) && (a = this._getOrReturnCtx(t, a), i ? p(a, {
          code: d.too_big,
          maximum: s.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: s.message
        }) : o && p(a, {
          code: d.too_small,
          minimum: s.value,
          type: "string",
          inclusive: !0,
          exact: !0,
          message: s.message
        }), n.dirty());
      } else if (s.kind === "email")
        Dp.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "email",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "emoji")
        Nt || (Nt = new RegExp(Vp, "u")), Nt.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "emoji",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "uuid")
        Mp.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "uuid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "cuid")
        Pp.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "cuid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "cuid2")
        Lp.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "cuid2",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "ulid")
        Zp.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "ulid",
          code: d.invalid_string,
          message: s.message
        }), n.dirty());
      else if (s.kind === "url")
        try {
          new URL(t.data);
        } catch {
          a = this._getOrReturnCtx(t, a), p(a, {
            validation: "url",
            code: d.invalid_string,
            message: s.message
          }), n.dirty();
        }
      else
        s.kind === "regex" ? (s.regex.lastIndex = 0, s.regex.test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "regex",
          code: d.invalid_string,
          message: s.message
        }), n.dirty())) : s.kind === "trim" ? t.data = t.data.trim() : s.kind === "includes" ? t.data.includes(s.value, s.position) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { includes: s.value, position: s.position },
          message: s.message
        }), n.dirty()) : s.kind === "toLowerCase" ? t.data = t.data.toLowerCase() : s.kind === "toUpperCase" ? t.data = t.data.toUpperCase() : s.kind === "startsWith" ? t.data.startsWith(s.value) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { startsWith: s.value },
          message: s.message
        }), n.dirty()) : s.kind === "endsWith" ? t.data.endsWith(s.value) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: { endsWith: s.value },
          message: s.message
        }), n.dirty()) : s.kind === "datetime" ? Bp(s).test(t.data) || (a = this._getOrReturnCtx(t, a), p(a, {
          code: d.invalid_string,
          validation: "datetime",
          message: s.message
        }), n.dirty()) : s.kind === "ip" ? Gp(t.data, s.version) || (a = this._getOrReturnCtx(t, a), p(a, {
          validation: "ip",
          code: d.invalid_string,
          message: s.message
        }), n.dirty()) : $.assertNever(s);
    return { status: n.value, value: t.data };
  }
  _regex(t, r, n) {
    return this.refinement((a) => t.test(a), {
      validation: r,
      code: d.invalid_string,
      ...v.errToObj(n)
    });
  }
  _addCheck(t) {
    return new Z({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  email(t) {
    return this._addCheck({ kind: "email", ...v.errToObj(t) });
  }
  url(t) {
    return this._addCheck({ kind: "url", ...v.errToObj(t) });
  }
  emoji(t) {
    return this._addCheck({ kind: "emoji", ...v.errToObj(t) });
  }
  uuid(t) {
    return this._addCheck({ kind: "uuid", ...v.errToObj(t) });
  }
  cuid(t) {
    return this._addCheck({ kind: "cuid", ...v.errToObj(t) });
  }
  cuid2(t) {
    return this._addCheck({ kind: "cuid2", ...v.errToObj(t) });
  }
  ulid(t) {
    return this._addCheck({ kind: "ulid", ...v.errToObj(t) });
  }
  ip(t) {
    return this._addCheck({ kind: "ip", ...v.errToObj(t) });
  }
  datetime(t) {
    var r;
    return typeof t == "string" ? this._addCheck({
      kind: "datetime",
      precision: null,
      offset: !1,
      message: t
    }) : this._addCheck({
      kind: "datetime",
      precision: typeof (t == null ? void 0 : t.precision) > "u" ? null : t == null ? void 0 : t.precision,
      offset: (r = t == null ? void 0 : t.offset) !== null && r !== void 0 ? r : !1,
      ...v.errToObj(t == null ? void 0 : t.message)
    });
  }
  regex(t, r) {
    return this._addCheck({
      kind: "regex",
      regex: t,
      ...v.errToObj(r)
    });
  }
  includes(t, r) {
    return this._addCheck({
      kind: "includes",
      value: t,
      position: r == null ? void 0 : r.position,
      ...v.errToObj(r == null ? void 0 : r.message)
    });
  }
  startsWith(t, r) {
    return this._addCheck({
      kind: "startsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  endsWith(t, r) {
    return this._addCheck({
      kind: "endsWith",
      value: t,
      ...v.errToObj(r)
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t,
      ...v.errToObj(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t,
      ...v.errToObj(r)
    });
  }
  length(t, r) {
    return this._addCheck({
      kind: "length",
      value: t,
      ...v.errToObj(r)
    });
  }
  /**
   * @deprecated Use z.string().min(1) instead.
   * @see {@link ZodString.min}
   */
  nonempty(t) {
    return this.min(1, v.errToObj(t));
  }
  trim() {
    return new Z({
      ...this._def,
      checks: [...this._def.checks, { kind: "trim" }]
    });
  }
  toLowerCase() {
    return new Z({
      ...this._def,
      checks: [...this._def.checks, { kind: "toLowerCase" }]
    });
  }
  toUpperCase() {
    return new Z({
      ...this._def,
      checks: [...this._def.checks, { kind: "toUpperCase" }]
    });
  }
  get isDatetime() {
    return !!this._def.checks.find((t) => t.kind === "datetime");
  }
  get isEmail() {
    return !!this._def.checks.find((t) => t.kind === "email");
  }
  get isURL() {
    return !!this._def.checks.find((t) => t.kind === "url");
  }
  get isEmoji() {
    return !!this._def.checks.find((t) => t.kind === "emoji");
  }
  get isUUID() {
    return !!this._def.checks.find((t) => t.kind === "uuid");
  }
  get isCUID() {
    return !!this._def.checks.find((t) => t.kind === "cuid");
  }
  get isCUID2() {
    return !!this._def.checks.find((t) => t.kind === "cuid2");
  }
  get isULID() {
    return !!this._def.checks.find((t) => t.kind === "ulid");
  }
  get isIP() {
    return !!this._def.checks.find((t) => t.kind === "ip");
  }
  get minLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxLength() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
Z.create = (e) => {
  var t;
  return new Z({
    checks: [],
    typeName: y.ZodString,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ..._(e)
  });
};
function Hp(e, t) {
  const r = (e.toString().split(".")[1] || "").length, n = (t.toString().split(".")[1] || "").length, a = r > n ? r : n, s = parseInt(e.toFixed(a).replace(".", "")), i = parseInt(t.toFixed(a).replace(".", ""));
  return s % i / Math.pow(10, a);
}
class Q extends b {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte, this.step = this.multipleOf;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = Number(t.data)), this._getType(t) !== f.number) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.number,
        received: s.parsedType
      }), m;
    }
    let n;
    const a = new I();
    for (const s of this._def.checks)
      s.kind === "int" ? $.isInteger(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.invalid_type,
        expected: "integer",
        received: "float",
        message: s.message
      }), a.dirty()) : s.kind === "min" ? (s.inclusive ? t.data < s.value : t.data <= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        minimum: s.value,
        type: "number",
        inclusive: s.inclusive,
        exact: !1,
        message: s.message
      }), a.dirty()) : s.kind === "max" ? (s.inclusive ? t.data > s.value : t.data >= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        maximum: s.value,
        type: "number",
        inclusive: s.inclusive,
        exact: !1,
        message: s.message
      }), a.dirty()) : s.kind === "multipleOf" ? Hp(t.data, s.value) !== 0 && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: s.value,
        message: s.message
      }), a.dirty()) : s.kind === "finite" ? Number.isFinite(t.data) || (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_finite,
        message: s.message
      }), a.dirty()) : $.assertNever(s);
    return { status: a.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, a) {
    return new Q({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(a)
        }
      ]
    });
  }
  _addCheck(t) {
    return new Q({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  int(t) {
    return this._addCheck({
      kind: "int",
      message: v.toString(t)
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: 0,
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  finite(t) {
    return this._addCheck({
      kind: "finite",
      message: v.toString(t)
    });
  }
  safe(t) {
    return this._addCheck({
      kind: "min",
      inclusive: !0,
      value: Number.MIN_SAFE_INTEGER,
      message: v.toString(t)
    })._addCheck({
      kind: "max",
      inclusive: !0,
      value: Number.MAX_SAFE_INTEGER,
      message: v.toString(t)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
  get isInt() {
    return !!this._def.checks.find((t) => t.kind === "int" || t.kind === "multipleOf" && $.isInteger(t.value));
  }
  get isFinite() {
    let t = null, r = null;
    for (const n of this._def.checks) {
      if (n.kind === "finite" || n.kind === "int" || n.kind === "multipleOf")
        return !0;
      n.kind === "min" ? (r === null || n.value > r) && (r = n.value) : n.kind === "max" && (t === null || n.value < t) && (t = n.value);
    }
    return Number.isFinite(r) && Number.isFinite(t);
  }
}
Q.create = (e) => new Q({
  checks: [],
  typeName: y.ZodNumber,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ..._(e)
});
class J extends b {
  constructor() {
    super(...arguments), this.min = this.gte, this.max = this.lte;
  }
  _parse(t) {
    if (this._def.coerce && (t.data = BigInt(t.data)), this._getType(t) !== f.bigint) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.bigint,
        received: s.parsedType
      }), m;
    }
    let n;
    const a = new I();
    for (const s of this._def.checks)
      s.kind === "min" ? (s.inclusive ? t.data < s.value : t.data <= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_small,
        type: "bigint",
        minimum: s.value,
        inclusive: s.inclusive,
        message: s.message
      }), a.dirty()) : s.kind === "max" ? (s.inclusive ? t.data > s.value : t.data >= s.value) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.too_big,
        type: "bigint",
        maximum: s.value,
        inclusive: s.inclusive,
        message: s.message
      }), a.dirty()) : s.kind === "multipleOf" ? t.data % s.value !== BigInt(0) && (n = this._getOrReturnCtx(t, n), p(n, {
        code: d.not_multiple_of,
        multipleOf: s.value,
        message: s.message
      }), a.dirty()) : $.assertNever(s);
    return { status: a.value, value: t.data };
  }
  gte(t, r) {
    return this.setLimit("min", t, !0, v.toString(r));
  }
  gt(t, r) {
    return this.setLimit("min", t, !1, v.toString(r));
  }
  lte(t, r) {
    return this.setLimit("max", t, !0, v.toString(r));
  }
  lt(t, r) {
    return this.setLimit("max", t, !1, v.toString(r));
  }
  setLimit(t, r, n, a) {
    return new J({
      ...this._def,
      checks: [
        ...this._def.checks,
        {
          kind: t,
          value: r,
          inclusive: n,
          message: v.toString(a)
        }
      ]
    });
  }
  _addCheck(t) {
    return new J({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  positive(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  negative(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !1,
      message: v.toString(t)
    });
  }
  nonpositive(t) {
    return this._addCheck({
      kind: "max",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  nonnegative(t) {
    return this._addCheck({
      kind: "min",
      value: BigInt(0),
      inclusive: !0,
      message: v.toString(t)
    });
  }
  multipleOf(t, r) {
    return this._addCheck({
      kind: "multipleOf",
      value: t,
      message: v.toString(r)
    });
  }
  get minValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t;
  }
  get maxValue() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t;
  }
}
J.create = (e) => {
  var t;
  return new J({
    checks: [],
    typeName: y.ZodBigInt,
    coerce: (t = e == null ? void 0 : e.coerce) !== null && t !== void 0 ? t : !1,
    ..._(e)
  });
};
class Ee extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = !!t.data), this._getType(t) !== f.boolean) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.boolean,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
Ee.create = (e) => new Ee({
  typeName: y.ZodBoolean,
  coerce: (e == null ? void 0 : e.coerce) || !1,
  ..._(e)
});
class se extends b {
  _parse(t) {
    if (this._def.coerce && (t.data = new Date(t.data)), this._getType(t) !== f.date) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_type,
        expected: f.date,
        received: s.parsedType
      }), m;
    }
    if (isNaN(t.data.getTime())) {
      const s = this._getOrReturnCtx(t);
      return p(s, {
        code: d.invalid_date
      }), m;
    }
    const n = new I();
    let a;
    for (const s of this._def.checks)
      s.kind === "min" ? t.data.getTime() < s.value && (a = this._getOrReturnCtx(t, a), p(a, {
        code: d.too_small,
        message: s.message,
        inclusive: !0,
        exact: !1,
        minimum: s.value,
        type: "date"
      }), n.dirty()) : s.kind === "max" ? t.data.getTime() > s.value && (a = this._getOrReturnCtx(t, a), p(a, {
        code: d.too_big,
        message: s.message,
        inclusive: !0,
        exact: !1,
        maximum: s.value,
        type: "date"
      }), n.dirty()) : $.assertNever(s);
    return {
      status: n.value,
      value: new Date(t.data.getTime())
    };
  }
  _addCheck(t) {
    return new se({
      ...this._def,
      checks: [...this._def.checks, t]
    });
  }
  min(t, r) {
    return this._addCheck({
      kind: "min",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  max(t, r) {
    return this._addCheck({
      kind: "max",
      value: t.getTime(),
      message: v.toString(r)
    });
  }
  get minDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "min" && (t === null || r.value > t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
  get maxDate() {
    let t = null;
    for (const r of this._def.checks)
      r.kind === "max" && (t === null || r.value < t) && (t = r.value);
    return t != null ? new Date(t) : null;
  }
}
se.create = (e) => new se({
  checks: [],
  coerce: (e == null ? void 0 : e.coerce) || !1,
  typeName: y.ZodDate,
  ..._(e)
});
class ut extends b {
  _parse(t) {
    if (this._getType(t) !== f.symbol) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.symbol,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
ut.create = (e) => new ut({
  typeName: y.ZodSymbol,
  ..._(e)
});
class je extends b {
  _parse(t) {
    if (this._getType(t) !== f.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.undefined,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
je.create = (e) => new je({
  typeName: y.ZodUndefined,
  ..._(e)
});
class ke extends b {
  _parse(t) {
    if (this._getType(t) !== f.null) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.null,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
ke.create = (e) => new ke({
  typeName: y.ZodNull,
  ..._(e)
});
class pe extends b {
  constructor() {
    super(...arguments), this._any = !0;
  }
  _parse(t) {
    return N(t.data);
  }
}
pe.create = (e) => new pe({
  typeName: y.ZodAny,
  ..._(e)
});
class ae extends b {
  constructor() {
    super(...arguments), this._unknown = !0;
  }
  _parse(t) {
    return N(t.data);
  }
}
ae.create = (e) => new ae({
  typeName: y.ZodUnknown,
  ..._(e)
});
class z extends b {
  _parse(t) {
    const r = this._getOrReturnCtx(t);
    return p(r, {
      code: d.invalid_type,
      expected: f.never,
      received: r.parsedType
    }), m;
  }
}
z.create = (e) => new z({
  typeName: y.ZodNever,
  ..._(e)
});
class ct extends b {
  _parse(t) {
    if (this._getType(t) !== f.undefined) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.void,
        received: n.parsedType
      }), m;
    }
    return N(t.data);
  }
}
ct.create = (e) => new ct({
  typeName: y.ZodVoid,
  ..._(e)
});
class D extends b {
  _parse(t) {
    const { ctx: r, status: n } = this._processInputParams(t), a = this._def;
    if (r.parsedType !== f.array)
      return p(r, {
        code: d.invalid_type,
        expected: f.array,
        received: r.parsedType
      }), m;
    if (a.exactLength !== null) {
      const i = r.data.length > a.exactLength.value, o = r.data.length < a.exactLength.value;
      (i || o) && (p(r, {
        code: i ? d.too_big : d.too_small,
        minimum: o ? a.exactLength.value : void 0,
        maximum: i ? a.exactLength.value : void 0,
        type: "array",
        inclusive: !0,
        exact: !0,
        message: a.exactLength.message
      }), n.dirty());
    }
    if (a.minLength !== null && r.data.length < a.minLength.value && (p(r, {
      code: d.too_small,
      minimum: a.minLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: a.minLength.message
    }), n.dirty()), a.maxLength !== null && r.data.length > a.maxLength.value && (p(r, {
      code: d.too_big,
      maximum: a.maxLength.value,
      type: "array",
      inclusive: !0,
      exact: !1,
      message: a.maxLength.message
    }), n.dirty()), r.common.async)
      return Promise.all([...r.data].map((i, o) => a.type._parseAsync(new U(r, i, r.path, o)))).then((i) => I.mergeArray(n, i));
    const s = [...r.data].map((i, o) => a.type._parseSync(new U(r, i, r.path, o)));
    return I.mergeArray(n, s);
  }
  get element() {
    return this._def.type;
  }
  min(t, r) {
    return new D({
      ...this._def,
      minLength: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new D({
      ...this._def,
      maxLength: { value: t, message: v.toString(r) }
    });
  }
  length(t, r) {
    return new D({
      ...this._def,
      exactLength: { value: t, message: v.toString(r) }
    });
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
D.create = (e, t) => new D({
  type: e,
  minLength: null,
  maxLength: null,
  exactLength: null,
  typeName: y.ZodArray,
  ..._(t)
});
function de(e) {
  if (e instanceof S) {
    const t = {};
    for (const r in e.shape) {
      const n = e.shape[r];
      t[r] = H.create(de(n));
    }
    return new S({
      ...e._def,
      shape: () => t
    });
  } else
    return e instanceof D ? new D({
      ...e._def,
      type: de(e.element)
    }) : e instanceof H ? H.create(de(e.unwrap())) : e instanceof oe ? oe.create(de(e.unwrap())) : e instanceof q ? q.create(e.items.map((t) => de(t))) : e;
}
class S extends b {
  constructor() {
    super(...arguments), this._cached = null, this.nonstrict = this.passthrough, this.augment = this.extend;
  }
  _getCached() {
    if (this._cached !== null)
      return this._cached;
    const t = this._def.shape(), r = $.objectKeys(t);
    return this._cached = { shape: t, keys: r };
  }
  _parse(t) {
    if (this._getType(t) !== f.object) {
      const c = this._getOrReturnCtx(t);
      return p(c, {
        code: d.invalid_type,
        expected: f.object,
        received: c.parsedType
      }), m;
    }
    const { status: n, ctx: a } = this._processInputParams(t), { shape: s, keys: i } = this._getCached(), o = [];
    if (!(this._def.catchall instanceof z && this._def.unknownKeys === "strip"))
      for (const c in a.data)
        i.includes(c) || o.push(c);
    const l = [];
    for (const c of i) {
      const h = s[c], T = a.data[c];
      l.push({
        key: { status: "valid", value: c },
        value: h._parse(new U(a, T, a.path, c)),
        alwaysSet: c in a.data
      });
    }
    if (this._def.catchall instanceof z) {
      const c = this._def.unknownKeys;
      if (c === "passthrough")
        for (const h of o)
          l.push({
            key: { status: "valid", value: h },
            value: { status: "valid", value: a.data[h] }
          });
      else if (c === "strict")
        o.length > 0 && (p(a, {
          code: d.unrecognized_keys,
          keys: o
        }), n.dirty());
      else if (c !== "strip")
        throw new Error("Internal ZodObject error: invalid unknownKeys value.");
    } else {
      const c = this._def.catchall;
      for (const h of o) {
        const T = a.data[h];
        l.push({
          key: { status: "valid", value: h },
          value: c._parse(
            new U(a, T, a.path, h)
            //, ctx.child(key), value, getParsedType(value)
          ),
          alwaysSet: h in a.data
        });
      }
    }
    return a.common.async ? Promise.resolve().then(async () => {
      const c = [];
      for (const h of l) {
        const T = await h.key;
        c.push({
          key: T,
          value: await h.value,
          alwaysSet: h.alwaysSet
        });
      }
      return c;
    }).then((c) => I.mergeObjectSync(n, c)) : I.mergeObjectSync(n, l);
  }
  get shape() {
    return this._def.shape();
  }
  strict(t) {
    return v.errToObj, new S({
      ...this._def,
      unknownKeys: "strict",
      ...t !== void 0 ? {
        errorMap: (r, n) => {
          var a, s, i, o;
          const l = (i = (s = (a = this._def).errorMap) === null || s === void 0 ? void 0 : s.call(a, r, n).message) !== null && i !== void 0 ? i : n.defaultError;
          return r.code === "unrecognized_keys" ? {
            message: (o = v.errToObj(t).message) !== null && o !== void 0 ? o : l
          } : {
            message: l
          };
        }
      } : {}
    });
  }
  strip() {
    return new S({
      ...this._def,
      unknownKeys: "strip"
    });
  }
  passthrough() {
    return new S({
      ...this._def,
      unknownKeys: "passthrough"
    });
  }
  // const AugmentFactory =
  //   <Def extends ZodObjectDef>(def: Def) =>
  //   <Augmentation extends ZodRawShape>(
  //     augmentation: Augmentation
  //   ): ZodObject<
  //     extendShape<ReturnType<Def["shape"]>, Augmentation>,
  //     Def["unknownKeys"],
  //     Def["catchall"]
  //   > => {
  //     return new ZodObject({
  //       ...def,
  //       shape: () => ({
  //         ...def.shape(),
  //         ...augmentation,
  //       }),
  //     }) as any;
  //   };
  extend(t) {
    return new S({
      ...this._def,
      shape: () => ({
        ...this._def.shape(),
        ...t
      })
    });
  }
  /**
   * Prior to zod@1.0.12 there was a bug in the
   * inferred type of merged objects. Please
   * upgrade if you are experiencing issues.
   */
  merge(t) {
    return new S({
      unknownKeys: t._def.unknownKeys,
      catchall: t._def.catchall,
      shape: () => ({
        ...this._def.shape(),
        ...t._def.shape()
      }),
      typeName: y.ZodObject
    });
  }
  // merge<
  //   Incoming extends AnyZodObject,
  //   Augmentation extends Incoming["shape"],
  //   NewOutput extends {
  //     [k in keyof Augmentation | keyof Output]: k extends keyof Augmentation
  //       ? Augmentation[k]["_output"]
  //       : k extends keyof Output
  //       ? Output[k]
  //       : never;
  //   },
  //   NewInput extends {
  //     [k in keyof Augmentation | keyof Input]: k extends keyof Augmentation
  //       ? Augmentation[k]["_input"]
  //       : k extends keyof Input
  //       ? Input[k]
  //       : never;
  //   }
  // >(
  //   merging: Incoming
  // ): ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"],
  //   NewOutput,
  //   NewInput
  // > {
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  setKey(t, r) {
    return this.augment({ [t]: r });
  }
  // merge<Incoming extends AnyZodObject>(
  //   merging: Incoming
  // ): //ZodObject<T & Incoming["_shape"], UnknownKeys, Catchall> = (merging) => {
  // ZodObject<
  //   extendShape<T, ReturnType<Incoming["_def"]["shape"]>>,
  //   Incoming["_def"]["unknownKeys"],
  //   Incoming["_def"]["catchall"]
  // > {
  //   // const mergedShape = objectUtil.mergeShapes(
  //   //   this._def.shape(),
  //   //   merging._def.shape()
  //   // );
  //   const merged: any = new ZodObject({
  //     unknownKeys: merging._def.unknownKeys,
  //     catchall: merging._def.catchall,
  //     shape: () =>
  //       objectUtil.mergeShapes(this._def.shape(), merging._def.shape()),
  //     typeName: ZodFirstPartyTypeKind.ZodObject,
  //   }) as any;
  //   return merged;
  // }
  catchall(t) {
    return new S({
      ...this._def,
      catchall: t
    });
  }
  pick(t) {
    const r = {};
    return $.objectKeys(t).forEach((n) => {
      t[n] && this.shape[n] && (r[n] = this.shape[n]);
    }), new S({
      ...this._def,
      shape: () => r
    });
  }
  omit(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      t[n] || (r[n] = this.shape[n]);
    }), new S({
      ...this._def,
      shape: () => r
    });
  }
  /**
   * @deprecated
   */
  deepPartial() {
    return de(this);
  }
  partial(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      const a = this.shape[n];
      t && !t[n] ? r[n] = a : r[n] = a.optional();
    }), new S({
      ...this._def,
      shape: () => r
    });
  }
  required(t) {
    const r = {};
    return $.objectKeys(this.shape).forEach((n) => {
      if (t && !t[n])
        r[n] = this.shape[n];
      else {
        let s = this.shape[n];
        for (; s instanceof H; )
          s = s._def.innerType;
        r[n] = s;
      }
    }), new S({
      ...this._def,
      shape: () => r
    });
  }
  keyof() {
    return Kn($.objectKeys(this.shape));
  }
}
S.create = (e, t) => new S({
  shape: () => e,
  unknownKeys: "strip",
  catchall: z.create(),
  typeName: y.ZodObject,
  ..._(t)
});
S.strictCreate = (e, t) => new S({
  shape: () => e,
  unknownKeys: "strict",
  catchall: z.create(),
  typeName: y.ZodObject,
  ..._(t)
});
S.lazycreate = (e, t) => new S({
  shape: e,
  unknownKeys: "strip",
  catchall: z.create(),
  typeName: y.ZodObject,
  ..._(t)
});
class Ce extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = this._def.options;
    function a(s) {
      for (const o of s)
        if (o.result.status === "valid")
          return o.result;
      for (const o of s)
        if (o.result.status === "dirty")
          return r.common.issues.push(...o.ctx.common.issues), o.result;
      const i = s.map((o) => new M(o.ctx.common.issues));
      return p(r, {
        code: d.invalid_union,
        unionErrors: i
      }), m;
    }
    if (r.common.async)
      return Promise.all(n.map(async (s) => {
        const i = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        };
        return {
          result: await s._parseAsync({
            data: r.data,
            path: r.path,
            parent: i
          }),
          ctx: i
        };
      })).then(a);
    {
      let s;
      const i = [];
      for (const l of n) {
        const c = {
          ...r,
          common: {
            ...r.common,
            issues: []
          },
          parent: null
        }, h = l._parseSync({
          data: r.data,
          path: r.path,
          parent: c
        });
        if (h.status === "valid")
          return h;
        h.status === "dirty" && !s && (s = { result: h, ctx: c }), c.common.issues.length && i.push(c.common.issues);
      }
      if (s)
        return r.common.issues.push(...s.ctx.common.issues), s.result;
      const o = i.map((l) => new M(l));
      return p(r, {
        code: d.invalid_union,
        unionErrors: o
      }), m;
    }
  }
  get options() {
    return this._def.options;
  }
}
Ce.create = (e, t) => new Ce({
  options: e,
  typeName: y.ZodUnion,
  ..._(t)
});
const tt = (e) => e instanceof Ne ? tt(e.schema) : e instanceof V ? tt(e.innerType()) : e instanceof Pe ? [e.value] : e instanceof Y ? e.options : e instanceof Le ? Object.keys(e.enum) : e instanceof Ze ? tt(e._def.innerType) : e instanceof je ? [void 0] : e instanceof ke ? [null] : null;
class Ot extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.object)
      return p(r, {
        code: d.invalid_type,
        expected: f.object,
        received: r.parsedType
      }), m;
    const n = this.discriminator, a = r.data[n], s = this.optionsMap.get(a);
    return s ? r.common.async ? s._parseAsync({
      data: r.data,
      path: r.path,
      parent: r
    }) : s._parseSync({
      data: r.data,
      path: r.path,
      parent: r
    }) : (p(r, {
      code: d.invalid_union_discriminator,
      options: Array.from(this.optionsMap.keys()),
      path: [n]
    }), m);
  }
  get discriminator() {
    return this._def.discriminator;
  }
  get options() {
    return this._def.options;
  }
  get optionsMap() {
    return this._def.optionsMap;
  }
  /**
   * The constructor of the discriminated union schema. Its behaviour is very similar to that of the normal z.union() constructor.
   * However, it only allows a union of objects, all of which need to share a discriminator property. This property must
   * have a different value for each object in the union.
   * @param discriminator the name of the discriminator property
   * @param types an array of object schemas
   * @param params
   */
  static create(t, r, n) {
    const a = /* @__PURE__ */ new Map();
    for (const s of r) {
      const i = tt(s.shape[t]);
      if (!i)
        throw new Error(`A discriminator value for key \`${t}\` could not be extracted from all schema options`);
      for (const o of i) {
        if (a.has(o))
          throw new Error(`Discriminator property ${String(t)} has duplicate value ${String(o)}`);
        a.set(o, s);
      }
    }
    return new Ot({
      typeName: y.ZodDiscriminatedUnion,
      discriminator: t,
      options: r,
      optionsMap: a,
      ..._(n)
    });
  }
}
function Ht(e, t) {
  const r = K(e), n = K(t);
  if (e === t)
    return { valid: !0, data: e };
  if (r === f.object && n === f.object) {
    const a = $.objectKeys(t), s = $.objectKeys(e).filter((o) => a.indexOf(o) !== -1), i = { ...e, ...t };
    for (const o of s) {
      const l = Ht(e[o], t[o]);
      if (!l.valid)
        return { valid: !1 };
      i[o] = l.data;
    }
    return { valid: !0, data: i };
  } else if (r === f.array && n === f.array) {
    if (e.length !== t.length)
      return { valid: !1 };
    const a = [];
    for (let s = 0; s < e.length; s++) {
      const i = e[s], o = t[s], l = Ht(i, o);
      if (!l.valid)
        return { valid: !1 };
      a.push(l.data);
    }
    return { valid: !0, data: a };
  } else
    return r === f.date && n === f.date && +e == +t ? { valid: !0, data: e } : { valid: !1 };
}
class Ie extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), a = (s, i) => {
      if (Bt(s) || Bt(i))
        return m;
      const o = Ht(s.value, i.value);
      return o.valid ? ((Gt(s) || Gt(i)) && r.dirty(), { status: r.value, value: o.data }) : (p(n, {
        code: d.invalid_intersection_types
      }), m);
    };
    return n.common.async ? Promise.all([
      this._def.left._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      }),
      this._def.right._parseAsync({
        data: n.data,
        path: n.path,
        parent: n
      })
    ]).then(([s, i]) => a(s, i)) : a(this._def.left._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }), this._def.right._parseSync({
      data: n.data,
      path: n.path,
      parent: n
    }));
  }
}
Ie.create = (e, t, r) => new Ie({
  left: e,
  right: t,
  typeName: y.ZodIntersection,
  ..._(r)
});
class q extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.array)
      return p(n, {
        code: d.invalid_type,
        expected: f.array,
        received: n.parsedType
      }), m;
    if (n.data.length < this._def.items.length)
      return p(n, {
        code: d.too_small,
        minimum: this._def.items.length,
        inclusive: !0,
        exact: !1,
        type: "array"
      }), m;
    !this._def.rest && n.data.length > this._def.items.length && (p(n, {
      code: d.too_big,
      maximum: this._def.items.length,
      inclusive: !0,
      exact: !1,
      type: "array"
    }), r.dirty());
    const s = [...n.data].map((i, o) => {
      const l = this._def.items[o] || this._def.rest;
      return l ? l._parse(new U(n, i, n.path, o)) : null;
    }).filter((i) => !!i);
    return n.common.async ? Promise.all(s).then((i) => I.mergeArray(r, i)) : I.mergeArray(r, s);
  }
  get items() {
    return this._def.items;
  }
  rest(t) {
    return new q({
      ...this._def,
      rest: t
    });
  }
}
q.create = (e, t) => {
  if (!Array.isArray(e))
    throw new Error("You must pass an array of schemas to z.tuple([ ... ])");
  return new q({
    items: e,
    typeName: y.ZodTuple,
    rest: null,
    ..._(t)
  });
};
class Re extends b {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.object)
      return p(n, {
        code: d.invalid_type,
        expected: f.object,
        received: n.parsedType
      }), m;
    const a = [], s = this._def.keyType, i = this._def.valueType;
    for (const o in n.data)
      a.push({
        key: s._parse(new U(n, o, n.path, o)),
        value: i._parse(new U(n, n.data[o], n.path, o))
      });
    return n.common.async ? I.mergeObjectAsync(r, a) : I.mergeObjectSync(r, a);
  }
  get element() {
    return this._def.valueType;
  }
  static create(t, r, n) {
    return r instanceof b ? new Re({
      keyType: t,
      valueType: r,
      typeName: y.ZodRecord,
      ..._(n)
    }) : new Re({
      keyType: Z.create(),
      valueType: t,
      typeName: y.ZodRecord,
      ..._(r)
    });
  }
}
class lt extends b {
  get keySchema() {
    return this._def.keyType;
  }
  get valueSchema() {
    return this._def.valueType;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.map)
      return p(n, {
        code: d.invalid_type,
        expected: f.map,
        received: n.parsedType
      }), m;
    const a = this._def.keyType, s = this._def.valueType, i = [...n.data.entries()].map(([o, l], c) => ({
      key: a._parse(new U(n, o, n.path, [c, "key"])),
      value: s._parse(new U(n, l, n.path, [c, "value"]))
    }));
    if (n.common.async) {
      const o = /* @__PURE__ */ new Map();
      return Promise.resolve().then(async () => {
        for (const l of i) {
          const c = await l.key, h = await l.value;
          if (c.status === "aborted" || h.status === "aborted")
            return m;
          (c.status === "dirty" || h.status === "dirty") && r.dirty(), o.set(c.value, h.value);
        }
        return { status: r.value, value: o };
      });
    } else {
      const o = /* @__PURE__ */ new Map();
      for (const l of i) {
        const c = l.key, h = l.value;
        if (c.status === "aborted" || h.status === "aborted")
          return m;
        (c.status === "dirty" || h.status === "dirty") && r.dirty(), o.set(c.value, h.value);
      }
      return { status: r.value, value: o };
    }
  }
}
lt.create = (e, t, r) => new lt({
  valueType: t,
  keyType: e,
  typeName: y.ZodMap,
  ..._(r)
});
class ie extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.parsedType !== f.set)
      return p(n, {
        code: d.invalid_type,
        expected: f.set,
        received: n.parsedType
      }), m;
    const a = this._def;
    a.minSize !== null && n.data.size < a.minSize.value && (p(n, {
      code: d.too_small,
      minimum: a.minSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: a.minSize.message
    }), r.dirty()), a.maxSize !== null && n.data.size > a.maxSize.value && (p(n, {
      code: d.too_big,
      maximum: a.maxSize.value,
      type: "set",
      inclusive: !0,
      exact: !1,
      message: a.maxSize.message
    }), r.dirty());
    const s = this._def.valueType;
    function i(l) {
      const c = /* @__PURE__ */ new Set();
      for (const h of l) {
        if (h.status === "aborted")
          return m;
        h.status === "dirty" && r.dirty(), c.add(h.value);
      }
      return { status: r.value, value: c };
    }
    const o = [...n.data.values()].map((l, c) => s._parse(new U(n, l, n.path, c)));
    return n.common.async ? Promise.all(o).then((l) => i(l)) : i(o);
  }
  min(t, r) {
    return new ie({
      ...this._def,
      minSize: { value: t, message: v.toString(r) }
    });
  }
  max(t, r) {
    return new ie({
      ...this._def,
      maxSize: { value: t, message: v.toString(r) }
    });
  }
  size(t, r) {
    return this.min(t, r).max(t, r);
  }
  nonempty(t) {
    return this.min(1, t);
  }
}
ie.create = (e, t) => new ie({
  valueType: e,
  minSize: null,
  maxSize: null,
  typeName: y.ZodSet,
  ..._(t)
});
class fe extends b {
  constructor() {
    super(...arguments), this.validate = this.implement;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.function)
      return p(r, {
        code: d.invalid_type,
        expected: f.function,
        received: r.parsedType
      }), m;
    function n(o, l) {
      return it({
        data: o,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          st(),
          Se
        ].filter((c) => !!c),
        issueData: {
          code: d.invalid_arguments,
          argumentsError: l
        }
      });
    }
    function a(o, l) {
      return it({
        data: o,
        path: r.path,
        errorMaps: [
          r.common.contextualErrorMap,
          r.schemaErrorMap,
          st(),
          Se
        ].filter((c) => !!c),
        issueData: {
          code: d.invalid_return_type,
          returnTypeError: l
        }
      });
    }
    const s = { errorMap: r.common.contextualErrorMap }, i = r.data;
    if (this._def.returns instanceof he) {
      const o = this;
      return N(async function(...l) {
        const c = new M([]), h = await o._def.args.parseAsync(l, s).catch((C) => {
          throw c.addIssue(n(l, C)), c;
        }), T = await Reflect.apply(i, this, h);
        return await o._def.returns._def.type.parseAsync(T, s).catch((C) => {
          throw c.addIssue(a(T, C)), c;
        });
      });
    } else {
      const o = this;
      return N(function(...l) {
        const c = o._def.args.safeParse(l, s);
        if (!c.success)
          throw new M([n(l, c.error)]);
        const h = Reflect.apply(i, this, c.data), T = o._def.returns.safeParse(h, s);
        if (!T.success)
          throw new M([a(h, T.error)]);
        return T.data;
      });
    }
  }
  parameters() {
    return this._def.args;
  }
  returnType() {
    return this._def.returns;
  }
  args(...t) {
    return new fe({
      ...this._def,
      args: q.create(t).rest(ae.create())
    });
  }
  returns(t) {
    return new fe({
      ...this._def,
      returns: t
    });
  }
  implement(t) {
    return this.parse(t);
  }
  strictImplement(t) {
    return this.parse(t);
  }
  static create(t, r, n) {
    return new fe({
      args: t || q.create([]).rest(ae.create()),
      returns: r || ae.create(),
      typeName: y.ZodFunction,
      ..._(n)
    });
  }
}
class Ne extends b {
  get schema() {
    return this._def.getter();
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    return this._def.getter()._parse({ data: r.data, path: r.path, parent: r });
  }
}
Ne.create = (e, t) => new Ne({
  getter: e,
  typeName: y.ZodLazy,
  ..._(t)
});
class Pe extends b {
  _parse(t) {
    if (t.data !== this._def.value) {
      const r = this._getOrReturnCtx(t);
      return p(r, {
        received: r.data,
        code: d.invalid_literal,
        expected: this._def.value
      }), m;
    }
    return { status: "valid", value: t.data };
  }
  get value() {
    return this._def.value;
  }
}
Pe.create = (e, t) => new Pe({
  value: e,
  typeName: y.ZodLiteral,
  ..._(t)
});
function Kn(e, t) {
  return new Y({
    values: e,
    typeName: y.ZodEnum,
    ..._(t)
  });
}
class Y extends b {
  _parse(t) {
    if (typeof t.data != "string") {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        expected: $.joinValues(n),
        received: r.parsedType,
        code: d.invalid_type
      }), m;
    }
    if (this._def.values.indexOf(t.data) === -1) {
      const r = this._getOrReturnCtx(t), n = this._def.values;
      return p(r, {
        received: r.data,
        code: d.invalid_enum_value,
        options: n
      }), m;
    }
    return N(t.data);
  }
  get options() {
    return this._def.values;
  }
  get enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Values() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  get Enum() {
    const t = {};
    for (const r of this._def.values)
      t[r] = r;
    return t;
  }
  extract(t) {
    return Y.create(t);
  }
  exclude(t) {
    return Y.create(this.options.filter((r) => !t.includes(r)));
  }
}
Y.create = Kn;
class Le extends b {
  _parse(t) {
    const r = $.getValidEnumValues(this._def.values), n = this._getOrReturnCtx(t);
    if (n.parsedType !== f.string && n.parsedType !== f.number) {
      const a = $.objectValues(r);
      return p(n, {
        expected: $.joinValues(a),
        received: n.parsedType,
        code: d.invalid_type
      }), m;
    }
    if (r.indexOf(t.data) === -1) {
      const a = $.objectValues(r);
      return p(n, {
        received: n.data,
        code: d.invalid_enum_value,
        options: a
      }), m;
    }
    return N(t.data);
  }
  get enum() {
    return this._def.values;
  }
}
Le.create = (e, t) => new Le({
  values: e,
  typeName: y.ZodNativeEnum,
  ..._(t)
});
class he extends b {
  unwrap() {
    return this._def.type;
  }
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    if (r.parsedType !== f.promise && r.common.async === !1)
      return p(r, {
        code: d.invalid_type,
        expected: f.promise,
        received: r.parsedType
      }), m;
    const n = r.parsedType === f.promise ? r.data : Promise.resolve(r.data);
    return N(n.then((a) => this._def.type.parseAsync(a, {
      path: r.path,
      errorMap: r.common.contextualErrorMap
    })));
  }
}
he.create = (e, t) => new he({
  type: e,
  typeName: y.ZodPromise,
  ..._(t)
});
class V extends b {
  innerType() {
    return this._def.schema;
  }
  sourceType() {
    return this._def.schema._def.typeName === y.ZodEffects ? this._def.schema.sourceType() : this._def.schema;
  }
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t), a = this._def.effect || null, s = {
      addIssue: (i) => {
        p(n, i), i.fatal ? r.abort() : r.dirty();
      },
      get path() {
        return n.path;
      }
    };
    if (s.addIssue = s.addIssue.bind(s), a.type === "preprocess") {
      const i = a.transform(n.data, s);
      return n.common.issues.length ? {
        status: "dirty",
        value: n.data
      } : n.common.async ? Promise.resolve(i).then((o) => this._def.schema._parseAsync({
        data: o,
        path: n.path,
        parent: n
      })) : this._def.schema._parseSync({
        data: i,
        path: n.path,
        parent: n
      });
    }
    if (a.type === "refinement") {
      const i = (o) => {
        const l = a.refinement(o, s);
        if (n.common.async)
          return Promise.resolve(l);
        if (l instanceof Promise)
          throw new Error("Async refinement encountered during synchronous parse operation. Use .parseAsync instead.");
        return o;
      };
      if (n.common.async === !1) {
        const o = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return o.status === "aborted" ? m : (o.status === "dirty" && r.dirty(), i(o.value), { status: r.value, value: o.value });
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((o) => o.status === "aborted" ? m : (o.status === "dirty" && r.dirty(), i(o.value).then(() => ({ status: r.value, value: o.value }))));
    }
    if (a.type === "transform")
      if (n.common.async === !1) {
        const i = this._def.schema._parseSync({
          data: n.data,
          path: n.path,
          parent: n
        });
        if (!Oe(i))
          return i;
        const o = a.transform(i.value, s);
        if (o instanceof Promise)
          throw new Error("Asynchronous transform encountered during synchronous parse operation. Use .parseAsync instead.");
        return { status: r.value, value: o };
      } else
        return this._def.schema._parseAsync({ data: n.data, path: n.path, parent: n }).then((i) => Oe(i) ? Promise.resolve(a.transform(i.value, s)).then((o) => ({ status: r.value, value: o })) : i);
    $.assertNever(a);
  }
}
V.create = (e, t, r) => new V({
  schema: e,
  typeName: y.ZodEffects,
  effect: t,
  ..._(r)
});
V.createWithPreprocess = (e, t, r) => new V({
  schema: t,
  effect: { type: "preprocess", transform: e },
  typeName: y.ZodEffects,
  ..._(r)
});
class H extends b {
  _parse(t) {
    return this._getType(t) === f.undefined ? N(void 0) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
H.create = (e, t) => new H({
  innerType: e,
  typeName: y.ZodOptional,
  ..._(t)
});
class oe extends b {
  _parse(t) {
    return this._getType(t) === f.null ? N(null) : this._def.innerType._parse(t);
  }
  unwrap() {
    return this._def.innerType;
  }
}
oe.create = (e, t) => new oe({
  innerType: e,
  typeName: y.ZodNullable,
  ..._(t)
});
class Ze extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t);
    let n = r.data;
    return r.parsedType === f.undefined && (n = this._def.defaultValue()), this._def.innerType._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  removeDefault() {
    return this._def.innerType;
  }
}
Ze.create = (e, t) => new Ze({
  innerType: e,
  typeName: y.ZodDefault,
  defaultValue: typeof t.default == "function" ? t.default : () => t.default,
  ..._(t)
});
class dt extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = {
      ...r,
      common: {
        ...r.common,
        issues: []
      }
    }, a = this._def.innerType._parse({
      data: n.data,
      path: n.path,
      parent: {
        ...n
      }
    });
    return ot(a) ? a.then((s) => ({
      status: "valid",
      value: s.status === "valid" ? s.value : this._def.catchValue({
        get error() {
          return new M(n.common.issues);
        },
        input: n.data
      })
    })) : {
      status: "valid",
      value: a.status === "valid" ? a.value : this._def.catchValue({
        get error() {
          return new M(n.common.issues);
        },
        input: n.data
      })
    };
  }
  removeCatch() {
    return this._def.innerType;
  }
}
dt.create = (e, t) => new dt({
  innerType: e,
  typeName: y.ZodCatch,
  catchValue: typeof t.catch == "function" ? t.catch : () => t.catch,
  ..._(t)
});
class ft extends b {
  _parse(t) {
    if (this._getType(t) !== f.nan) {
      const n = this._getOrReturnCtx(t);
      return p(n, {
        code: d.invalid_type,
        expected: f.nan,
        received: n.parsedType
      }), m;
    }
    return { status: "valid", value: t.data };
  }
}
ft.create = (e) => new ft({
  typeName: y.ZodNaN,
  ..._(e)
});
const zp = Symbol("zod_brand");
class Wn extends b {
  _parse(t) {
    const { ctx: r } = this._processInputParams(t), n = r.data;
    return this._def.type._parse({
      data: n,
      path: r.path,
      parent: r
    });
  }
  unwrap() {
    return this._def.type;
  }
}
class Ue extends b {
  _parse(t) {
    const { status: r, ctx: n } = this._processInputParams(t);
    if (n.common.async)
      return (async () => {
        const s = await this._def.in._parseAsync({
          data: n.data,
          path: n.path,
          parent: n
        });
        return s.status === "aborted" ? m : s.status === "dirty" ? (r.dirty(), Fn(s.value)) : this._def.out._parseAsync({
          data: s.value,
          path: n.path,
          parent: n
        });
      })();
    {
      const a = this._def.in._parseSync({
        data: n.data,
        path: n.path,
        parent: n
      });
      return a.status === "aborted" ? m : a.status === "dirty" ? (r.dirty(), {
        status: "dirty",
        value: a.value
      }) : this._def.out._parseSync({
        data: a.value,
        path: n.path,
        parent: n
      });
    }
  }
  static create(t, r) {
    return new Ue({
      in: t,
      out: r,
      typeName: y.ZodPipeline
    });
  }
}
class pt extends b {
  _parse(t) {
    const r = this._def.innerType._parse(t);
    return Oe(r) && (r.value = Object.freeze(r.value)), r;
  }
}
pt.create = (e, t) => new pt({
  innerType: e,
  typeName: y.ZodReadonly,
  ..._(t)
});
const Qn = (e, t = {}, r) => e ? pe.create().superRefine((n, a) => {
  var s, i;
  if (!e(n)) {
    const o = typeof t == "function" ? t(n) : typeof t == "string" ? { message: t } : t, l = (i = (s = o.fatal) !== null && s !== void 0 ? s : r) !== null && i !== void 0 ? i : !0, c = typeof o == "string" ? { message: o } : o;
    a.addIssue({ code: "custom", ...c, fatal: l });
  }
}) : pe.create(), Fp = {
  object: S.lazycreate
};
var y;
(function(e) {
  e.ZodString = "ZodString", e.ZodNumber = "ZodNumber", e.ZodNaN = "ZodNaN", e.ZodBigInt = "ZodBigInt", e.ZodBoolean = "ZodBoolean", e.ZodDate = "ZodDate", e.ZodSymbol = "ZodSymbol", e.ZodUndefined = "ZodUndefined", e.ZodNull = "ZodNull", e.ZodAny = "ZodAny", e.ZodUnknown = "ZodUnknown", e.ZodNever = "ZodNever", e.ZodVoid = "ZodVoid", e.ZodArray = "ZodArray", e.ZodObject = "ZodObject", e.ZodUnion = "ZodUnion", e.ZodDiscriminatedUnion = "ZodDiscriminatedUnion", e.ZodIntersection = "ZodIntersection", e.ZodTuple = "ZodTuple", e.ZodRecord = "ZodRecord", e.ZodMap = "ZodMap", e.ZodSet = "ZodSet", e.ZodFunction = "ZodFunction", e.ZodLazy = "ZodLazy", e.ZodLiteral = "ZodLiteral", e.ZodEnum = "ZodEnum", e.ZodEffects = "ZodEffects", e.ZodNativeEnum = "ZodNativeEnum", e.ZodOptional = "ZodOptional", e.ZodNullable = "ZodNullable", e.ZodDefault = "ZodDefault", e.ZodCatch = "ZodCatch", e.ZodPromise = "ZodPromise", e.ZodBranded = "ZodBranded", e.ZodPipeline = "ZodPipeline", e.ZodReadonly = "ZodReadonly";
})(y || (y = {}));
const Kp = (e, t = {
  message: `Input not instance of ${e.name}`
}) => Qn((r) => r instanceof e, t), Jn = Z.create, Yn = Q.create, Wp = ft.create, Qp = J.create, Xn = Ee.create, Jp = se.create, Yp = ut.create, Xp = je.create, eh = ke.create, th = pe.create, rh = ae.create, nh = z.create, ah = ct.create, sh = D.create, ih = S.create, oh = S.strictCreate, uh = Ce.create, ch = Ot.create, lh = Ie.create, dh = q.create, fh = Re.create, ph = lt.create, hh = ie.create, vh = fe.create, yh = Ne.create, gh = Pe.create, mh = Y.create, _h = Le.create, bh = he.create, Wr = V.create, Th = H.create, $h = oe.create, wh = V.createWithPreprocess, xh = Ue.create, Ah = () => Jn().optional(), Sh = () => Yn().optional(), Oh = () => Xn().optional(), Eh = {
  string: (e) => Z.create({ ...e, coerce: !0 }),
  number: (e) => Q.create({ ...e, coerce: !0 }),
  boolean: (e) => Ee.create({
    ...e,
    coerce: !0
  }),
  bigint: (e) => J.create({ ...e, coerce: !0 }),
  date: (e) => se.create({ ...e, coerce: !0 })
}, jh = m;
var u = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  defaultErrorMap: Se,
  setErrorMap: Rp,
  getErrorMap: st,
  makeIssue: it,
  EMPTY_PATH: Np,
  addIssueToContext: p,
  ParseStatus: I,
  INVALID: m,
  DIRTY: Fn,
  OK: N,
  isAborted: Bt,
  isDirty: Gt,
  isValid: Oe,
  isAsync: ot,
  get util() {
    return $;
  },
  get objectUtil() {
    return qt;
  },
  ZodParsedType: f,
  getParsedType: K,
  ZodType: b,
  ZodString: Z,
  ZodNumber: Q,
  ZodBigInt: J,
  ZodBoolean: Ee,
  ZodDate: se,
  ZodSymbol: ut,
  ZodUndefined: je,
  ZodNull: ke,
  ZodAny: pe,
  ZodUnknown: ae,
  ZodNever: z,
  ZodVoid: ct,
  ZodArray: D,
  ZodObject: S,
  ZodUnion: Ce,
  ZodDiscriminatedUnion: Ot,
  ZodIntersection: Ie,
  ZodTuple: q,
  ZodRecord: Re,
  ZodMap: lt,
  ZodSet: ie,
  ZodFunction: fe,
  ZodLazy: Ne,
  ZodLiteral: Pe,
  ZodEnum: Y,
  ZodNativeEnum: Le,
  ZodPromise: he,
  ZodEffects: V,
  ZodTransformer: V,
  ZodOptional: H,
  ZodNullable: oe,
  ZodDefault: Ze,
  ZodCatch: dt,
  ZodNaN: ft,
  BRAND: zp,
  ZodBranded: Wn,
  ZodPipeline: Ue,
  ZodReadonly: pt,
  custom: Qn,
  Schema: b,
  ZodSchema: b,
  late: Fp,
  get ZodFirstPartyTypeKind() {
    return y;
  },
  coerce: Eh,
  any: th,
  array: sh,
  bigint: Qp,
  boolean: Xn,
  date: Jp,
  discriminatedUnion: ch,
  effect: Wr,
  enum: mh,
  function: vh,
  instanceof: Kp,
  intersection: lh,
  lazy: yh,
  literal: gh,
  map: ph,
  nan: Wp,
  nativeEnum: _h,
  never: nh,
  null: eh,
  nullable: $h,
  number: Yn,
  object: ih,
  oboolean: Oh,
  onumber: Sh,
  optional: Th,
  ostring: Ah,
  pipeline: xh,
  preprocess: wh,
  promise: bh,
  record: fh,
  set: hh,
  strictObject: oh,
  string: Jn,
  symbol: Yp,
  transformer: Wr,
  tuple: dh,
  undefined: Xp,
  union: uh,
  unknown: rh,
  void: ah,
  NEVER: jh,
  ZodIssueCode: d,
  quotelessJson: Ip,
  ZodError: M
});
const kh = u.object({
  id: u.optional(u.string()),
  // Firebase Firestore ID
  url: u.string(),
  path: u.string(),
  headers: u.array(
    u.object({
      key: u.string(),
      value: u.string(),
      active: u.boolean()
    })
  ),
  params: u.array(
    u.object({
      key: u.string(),
      value: u.string(),
      active: u.boolean()
    })
  ),
  name: u.string(),
  method: u.string(),
  preRequestScript: u.string(),
  testScript: u.string(),
  contentType: u.string(),
  body: u.string(),
  rawParams: u.optional(u.string()),
  auth: u.optional(u.string()),
  httpUser: u.optional(u.string()),
  httpPassword: u.optional(u.string()),
  bearerToken: u.optional(u.string())
}), Qr = Ve({
  initial: !0,
  schema: kh
}), ea = u.object({
  key: u.string(),
  active: u.boolean()
}).and(
  u.union([
    u.object({
      isFile: u.literal(!0),
      value: u.array(u.instanceof(Blob))
    }),
    u.object({
      isFile: u.literal(!1),
      value: u.string()
    })
  ])
), jg = u.object({
  contentType: u.literal("multipart/form-data"),
  body: u.array(ea)
}), ta = u.union([
  u.object({
    contentType: u.literal(null),
    body: u.literal(null)
  }),
  u.object({
    contentType: u.literal("multipart/form-data"),
    body: ea
  }),
  u.object({
    contentType: u.union([
      u.literal("application/json"),
      u.literal("application/ld+json"),
      u.literal("application/hal+json"),
      u.literal("application/vnd.api+json"),
      u.literal("application/xml"),
      u.literal("application/x-www-form-urlencoded"),
      u.literal("text/html"),
      u.literal("text/plain")
    ]),
    body: u.string()
  })
]), Ch = u.object({
  authType: u.literal("none")
}), Ih = u.object({
  authType: u.literal("basic"),
  username: u.string(),
  password: u.string()
}), Rh = u.object({
  authType: u.literal("bearer"),
  token: u.string()
}), Nh = u.object({
  authType: u.literal("oauth-2"),
  token: u.string(),
  oidcDiscoveryURL: u.string(),
  authURL: u.string(),
  accessTokenURL: u.string(),
  clientID: u.string(),
  scope: u.string()
}), Ph = u.object({
  authType: u.literal("api-key"),
  key: u.string(),
  value: u.string(),
  addTo: u.string()
}), ra = u.discriminatedUnion("authType", [
  Ch,
  Ih,
  Rh,
  Nh,
  Ph
]).and(
  u.object({
    authActive: u.boolean()
  })
), na = u.array(
  u.object({
    key: u.string(),
    value: u.string(),
    active: u.boolean()
  })
), aa = u.array(
  u.object({
    key: u.string(),
    value: u.string(),
    active: u.boolean()
  })
), Lh = u.object({
  v: u.literal("1"),
  id: u.optional(u.string()),
  // Firebase Firestore ID
  name: u.string(),
  method: u.string(),
  endpoint: u.string(),
  params: na,
  headers: aa,
  preRequestScript: u.string(),
  testScript: u.string(),
  auth: ra,
  body: ta
});
function Zh(e) {
  return {
    contentType: "application/json",
    body: e.contentType === "application/json" ? e.rawParams ?? "" : ""
  };
}
function Mh(e) {
  return !e.auth || e.auth === "None" ? {
    authType: "none",
    authActive: !0
  } : e.auth === "Basic Auth" ? {
    authType: "basic",
    authActive: !0,
    username: e.httpUser ?? "",
    password: e.httpPassword ?? ""
  } : e.auth === "Bearer Token" ? {
    authType: "bearer",
    authActive: !0,
    token: e.bearerToken ?? ""
  } : { authType: "none", authActive: !0 };
}
const Dh = Ve({
  initial: !1,
  schema: Lh,
  up(e) {
    const { url: t, path: r, headers: n, params: a, name: s, method: i, preRequestScript: o, testScript: l } = e, c = `${t}${r}`, h = Zh(e), T = Mh(e), w = {
      v: "1",
      endpoint: c,
      headers: n,
      params: a,
      name: s,
      method: i,
      preRequestScript: o,
      testScript: l,
      body: h,
      auth: T
    };
    return e.id && (w.id = e.id), w;
  }
});
var Vh = "__lodash_hash_undefined__";
function Uh(e) {
  return this.__data__.set(e, Vh), this;
}
var qh = Uh;
function Bh(e) {
  return this.__data__.has(e);
}
var Gh = Bh, Hh = An, zh = qh, Fh = Gh;
function ht(e) {
  var t = -1, r = e == null ? 0 : e.length;
  for (this.__data__ = new Hh(); ++t < r; )
    this.add(e[t]);
}
ht.prototype.add = ht.prototype.push = zh;
ht.prototype.has = Fh;
var Kh = ht;
function Wh(e, t) {
  for (var r = -1, n = e == null ? 0 : e.length; ++r < n; )
    if (t(e[r], r, e))
      return !0;
  return !1;
}
var Qh = Wh;
function Jh(e, t) {
  return e.has(t);
}
var Yh = Jh, Xh = Kh, ev = Qh, tv = Yh, rv = 1, nv = 2;
function av(e, t, r, n, a, s) {
  var i = r & rv, o = e.length, l = t.length;
  if (o != l && !(i && l > o))
    return !1;
  var c = s.get(e), h = s.get(t);
  if (c && h)
    return c == t && h == e;
  var T = -1, w = !0, C = r & nv ? new Xh() : void 0;
  for (s.set(e, t), s.set(t, e); ++T < o; ) {
    var P = e[T], L = t[T];
    if (n)
      var k = i ? n(L, P, T, t, e, s) : n(P, L, T, e, t, s);
    if (k !== void 0) {
      if (k)
        continue;
      w = !1;
      break;
    }
    if (C) {
      if (!ev(t, function(R, re) {
        if (!tv(C, re) && (P === R || a(P, R, r, n, s)))
          return C.push(re);
      })) {
        w = !1;
        break;
      }
    } else if (!(P === L || a(P, L, r, n, s))) {
      w = !1;
      break;
    }
  }
  return s.delete(e), s.delete(t), w;
}
var sa = av;
function sv(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n, a) {
    r[++t] = [a, n];
  }), r;
}
var iv = sv;
function ov(e) {
  var t = -1, r = Array(e.size);
  return e.forEach(function(n) {
    r[++t] = n;
  }), r;
}
var uv = ov, Jr = bt, Yr = qn, cv = Jt, lv = sa, dv = iv, fv = uv, pv = 1, hv = 2, vv = "[object Boolean]", yv = "[object Date]", gv = "[object Error]", mv = "[object Map]", _v = "[object Number]", bv = "[object RegExp]", Tv = "[object Set]", $v = "[object String]", wv = "[object Symbol]", xv = "[object ArrayBuffer]", Av = "[object DataView]", Xr = Jr ? Jr.prototype : void 0, Pt = Xr ? Xr.valueOf : void 0;
function Sv(e, t, r, n, a, s, i) {
  switch (r) {
    case Av:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case xv:
      return !(e.byteLength != t.byteLength || !s(new Yr(e), new Yr(t)));
    case vv:
    case yv:
    case _v:
      return cv(+e, +t);
    case gv:
      return e.name == t.name && e.message == t.message;
    case bv:
    case $v:
      return e == t + "";
    case mv:
      var o = dv;
    case Tv:
      var l = n & pv;
      if (o || (o = fv), e.size != t.size && !l)
        return !1;
      var c = i.get(e);
      if (c)
        return c == t;
      n |= hv, i.set(e, t);
      var h = lv(o(e), o(t), n, a, s, i);
      return i.delete(e), h;
    case wv:
      if (Pt)
        return Pt.call(e) == Pt.call(t);
  }
  return !1;
}
var Ov = Sv, en = Vn, Ev = 1, jv = Object.prototype, kv = jv.hasOwnProperty;
function Cv(e, t, r, n, a, s) {
  var i = r & Ev, o = en(e), l = o.length, c = en(t), h = c.length;
  if (l != h && !i)
    return !1;
  for (var T = l; T--; ) {
    var w = o[T];
    if (!(i ? w in t : kv.call(t, w)))
      return !1;
  }
  var C = s.get(e), P = s.get(t);
  if (C && P)
    return C == t && P == e;
  var L = !0;
  s.set(e, t), s.set(t, e);
  for (var k = i; ++T < l; ) {
    w = o[T];
    var R = e[w], re = t[w];
    if (n)
      var Tr = i ? n(re, R, w, t, e, s) : n(R, re, w, e, t, s);
    if (!(Tr === void 0 ? R === re || a(R, re, r, n, s) : Tr)) {
      L = !1;
      break;
    }
    k || (k = w == "constructor");
  }
  if (L && !k) {
    var We = e.constructor, Qe = t.constructor;
    We != Qe && "constructor" in e && "constructor" in t && !(typeof We == "function" && We instanceof We && typeof Qe == "function" && Qe instanceof Qe) && (L = !1);
  }
  return s.delete(e), s.delete(t), L;
}
var Iv = Cv, Lt = Sn, Rv = sa, Nv = Ov, Pv = Iv, tn = St, rn = At, nn = Xt, Lv = Cn, Zv = 1, an = "[object Arguments]", sn = "[object Array]", Ye = "[object Object]", Mv = Object.prototype, on = Mv.hasOwnProperty;
function Dv(e, t, r, n, a, s) {
  var i = rn(e), o = rn(t), l = i ? sn : tn(e), c = o ? sn : tn(t);
  l = l == an ? Ye : l, c = c == an ? Ye : c;
  var h = l == Ye, T = c == Ye, w = l == c;
  if (w && nn(e)) {
    if (!nn(t))
      return !1;
    i = !0, h = !1;
  }
  if (w && !h)
    return s || (s = new Lt()), i || Lv(e) ? Rv(e, t, r, n, a, s) : Nv(e, t, l, r, n, a, s);
  if (!(r & Zv)) {
    var C = h && on.call(e, "__wrapped__"), P = T && on.call(t, "__wrapped__");
    if (C || P) {
      var L = C ? e.value() : e, k = P ? t.value() : t;
      return s || (s = new Lt()), a(L, k, r, n, s);
    }
  }
  return w ? (s || (s = new Lt()), Pv(e, t, r, n, a, s)) : !1;
}
var Vv = Dv, Uv = Vv, un = we;
function ia(e, t, r, n, a) {
  return e === t ? !0 : e == null || t == null || !un(e) && !un(t) ? e !== e && t !== t : Uv(e, t, r, n, ia, a);
}
var qv = ia, Bv = qv;
function Gv(e, t) {
  return Bv(e, t);
}
var Hv = Gv;
const zv = /* @__PURE__ */ bn(Hv), Fv = (e) => ({
  equals(t, r) {
    return t !== void 0 && r !== void 0 ? e.equals(t, r) : t === void 0 && r === void 0;
  }
}), cn = (e, t) => ({
  equals(r, n) {
    return t.equals(e(r), e(n));
  }
}), Xe = {
  equals(e, t) {
    return zv(e, t);
  }
}, Kv = {
  "application/json": "json",
  "application/ld+json": "json",
  "application/hal+json": "json",
  "application/vnd.api+json": "json",
  "application/xml": "xml",
  "application/x-www-form-urlencoded": "multipart",
  "multipart/form-data": "multipart",
  "text/html": "html",
  "text/plain": "plain"
}, kg = Object.keys(Kv), Wv = u.object({
  // v is a stringified number
  v: u.string().regex(/^\d+$/).transform(Number)
}), oa = or({
  latestVersion: 1,
  versionMap: {
    0: Qr,
    1: Dh
  },
  getVersion(e) {
    const t = Wv.safeParse(e);
    return t.success ? t.data.v : Qr.schema.safeParse(e).success ? 0 : null;
  }
}), Qv = La({
  id: Fv(F),
  v: F,
  auth: Xe,
  body: Xe,
  endpoint: F,
  headers: cn(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    Xe
  ),
  params: cn(
    (e) => e.filter((t) => t.key !== "" && t.value !== ""),
    Xe
  ),
  method: F,
  name: F,
  preRequestScript: F,
  testScript: F
}), Jv = "1", Cg = Qv.equals;
function Ig(e, t) {
  const r = kp(t);
  if (e && typeof e == "object") {
    if ("id" in e && typeof e.id == "string" && (r.id = e.id), "name" in e && typeof e.name == "string" && (r.name = e.name), "method" in e && typeof e.method == "string" && (r.method = e.method), "endpoint" in e && typeof e.endpoint == "string" && (r.endpoint = e.endpoint), "preRequestScript" in e && typeof e.preRequestScript == "string" && (r.preRequestScript = e.preRequestScript), "testScript" in e && typeof e.testScript == "string" && (r.testScript = e.testScript), "body" in e) {
      const n = ta.safeParse(e.body);
      n.success && (r.body = n.data);
    }
    if ("auth" in e) {
      const n = ra.safeParse(e.auth);
      n.success && (r.auth = n.data);
    }
    if ("params" in e) {
      const n = na.safeParse(e.params);
      n.success && (r.params = n.data);
    }
    if ("headers" in e) {
      const n = aa.safeParse(e.headers);
      n.success && (r.headers = n.data);
    }
  }
  return r;
}
function Rg(e) {
  return {
    v: Jv,
    ...e
  };
}
function Yv() {
  return {
    v: "1",
    endpoint: "https://echo.hoppscotch.io",
    name: "Untitled",
    params: [],
    headers: [],
    method: "GET",
    auth: {
      authType: "none",
      authActive: !0
    },
    preRequestScript: "",
    testScript: "",
    body: {
      contentType: null,
      body: null
    }
  };
}
function Ng(e) {
  return oa.isLatest(e);
}
function Xv(e) {
  const t = oa.safeParse(e);
  return t.type === "ok" ? t.value : Yv();
}
const ua = u.object({
  key: u.string(),
  value: u.string(),
  active: u.boolean()
}), ey = u.object({
  v: u.literal(1),
  name: u.string(),
  url: u.string(),
  headers: u.array(ua),
  query: u.string(),
  variables: u.string()
}), ty = Ve({
  initial: !0,
  schema: ey
}), ry = u.object({
  authType: u.literal("none")
}), ny = u.object({
  authType: u.literal("basic"),
  username: u.string(),
  password: u.string()
}), ay = u.object({
  authType: u.literal("bearer"),
  token: u.string()
}), sy = u.object({
  authType: u.literal("oauth-2"),
  token: u.string(),
  oidcDiscoveryURL: u.string(),
  authURL: u.string(),
  accessTokenURL: u.string(),
  clientID: u.string(),
  scope: u.string()
}), iy = u.object({
  authType: u.literal("api-key"),
  key: u.string(),
  value: u.string(),
  addTo: u.string()
}), oy = u.discriminatedUnion("authType", [
  ry,
  ny,
  ay,
  sy,
  iy
]).and(
  u.object({
    authActive: u.boolean()
  })
), uy = u.object({
  id: u.optional(u.string()),
  v: u.literal(2),
  name: u.string(),
  url: u.string(),
  headers: u.array(ua),
  query: u.string(),
  variables: u.string(),
  auth: oy
}), cy = Ve({
  initial: !1,
  schema: uy,
  up(e) {
    return {
      ...e,
      v: 2,
      auth: {
        authActive: !0,
        authType: "none"
      }
    };
  }
}), ur = 2, ly = u.object({
  v: u.number()
}), dy = or({
  latestVersion: 2,
  versionMap: {
    1: ty,
    2: cy
  },
  getVersion(e) {
    const t = ly.safeParse(e);
    return t.success ? t.data.v : null;
  }
}), fy = `
query Request {
  method
  url
  headers {
    key
    value
  }
}`.trim();
function py() {
  return {
    v: ur,
    name: "Untitled",
    url: "https://echo.hoppscotch.io/graphql",
    headers: [],
    variables: `
{
  "id": "1"
}`.trim(),
    query: fy,
    auth: {
      authType: "none",
      authActive: !0
    }
  };
}
function hy(e) {
  const t = dy.safeParse(e);
  return t.type === "ok" ? t.value : py();
}
function Pg(e) {
  return {
    v: ur,
    ...e
  };
}
const vy = 1;
function ca(e) {
  return {
    v: vy,
    ...e
  };
}
function yy(e) {
  if (e.v && e.v === 1)
    return e;
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(yy), n = (e.requests ?? []).map(Xv), a = ca({
    name: t,
    folders: r,
    requests: n
  });
  return e.id && (a.id = e.id), a;
}
function gy(e) {
  if (e.v && e.v === ur)
    return e;
  const t = e.name ?? "Untitled", r = (e.folders ?? []).map(gy), n = (e.requests ?? []).map(hy), a = ca({
    name: t,
    folders: r,
    requests: n
  });
  return e.id && (a.id = e.id), a;
}
var ee = function(e) {
  return function(t) {
    return !e(t);
  };
};
globalThis && globalThis.__spreadArray;
var my = Xa, _y = Ja;
function la(e, t) {
  return t === void 0 ? function(r) {
    return la(e, r);
  } : _y(e, t) ? yn : gn(t[e]);
}
var da = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, by = function(e) {
  return function(t) {
    for (var r = [], n = 0; n < t.length; n++) {
      var a = e(n, t[n]);
      vn(a) && r.push(a.value);
    }
    return r;
  };
}, Ty = function(e) {
  return by(function(t, r) {
    return e(r);
  });
}, fa = function(e) {
  return e.slice();
}, $y = Qa, ln = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, a = t.length, s; n < a; n++)
      (s || !(n in t)) && (s || (s = Array.prototype.slice.call(t, 0, n)), s[n] = t[n]);
  return e.concat(s || Array.prototype.slice.call(t));
}, wy = function(e) {
  return function(t) {
    return ln(ln([], t, !0), [e], !1);
  };
}, xy = wy, Ay = function(e) {
  return [e];
}, Sy = xy, Oy = la, Ey = function(e) {
  return function(t) {
    return t.map(function(r) {
      return e(r);
    });
  };
}, jy = function() {
  return {
    concat: function(e, t) {
      return e.concat(t);
    }
  };
}, ky = function() {
  return {
    concat: jy().concat,
    empty: []
  };
}, Et = yn, qe = gn;
function pa(e) {
  return function(t) {
    return e(t) ? qe(t) : Et;
  };
}
var zt = function(e) {
  return function(t) {
    return ha(t) ? Et : qe(e(t.value));
  };
}, Cy = vn, ha = function(e) {
  return e._tag === "None";
}, Iy = function(e, t) {
  return function(r) {
    return ha(r) ? e() : t(r.value);
  };
}, Ry = Iy, cr = Ry, Ny = function(e, t) {
  for (var r = t(e); r._tag === "Left"; )
    r = t(r.left);
  return r.right;
}, te = Da, B = Va, Py = /* @__PURE__ */ Na(2, function(e, t) {
  return ve(e) ? e : t(e.right);
}), Be = function(e) {
  return function(t) {
    return ve(t) ? t : B(e(t.right));
  };
}, jt = function(e) {
  return function(t) {
    return ve(t) ? te(e(t.left)) : t;
  };
}, ve = Za, Ly = Ma, Zy = function(e) {
  return function(t) {
    return ve(t) ? e(t.left) : t.right;
  };
}, lr = Zy, dn = Py, vt = globalThis && globalThis.__assign || function() {
  return vt = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var a in t)
        Object.prototype.hasOwnProperty.call(t, a) && (e[a] = t[a]);
    }
    return e;
  }, vt.apply(this, arguments);
}, Ge = function(e, t, r) {
  return B({
    value: e,
    next: t,
    start: r
  });
}, He = function(e, t, r) {
  return t === void 0 && (t = []), r === void 0 && (r = !1), te({
    input: e,
    expected: t,
    fatal: r
  });
}, My = function(e, t) {
  return vt(vt({}, e), { expected: t });
}, Dy = function(e, t) {
  return Vy().concat(e, t);
}, Vy = function() {
  return {
    concat: function(e, t) {
      return e.input.cursor < t.input.cursor ? Fa().concat(e, t) : e.input.cursor > t.input.cursor ? It().concat(e, t) : za({
        input: It(),
        fatal: It(),
        expected: ky()
      }).concat(e, t);
    }
  };
}, Uy = function(e, t) {
  return t === void 0 && (t = 0), {
    buffer: e,
    cursor: t
  };
}, qy = function(e) {
  return Oy(e.cursor, e.buffer);
}, By = function(e) {
  return e.cursor >= e.buffer.length;
}, Gy = function(e) {
  return g(qy(e), zt(function(t) {
    return { value: t, next: { buffer: e.buffer, cursor: e.cursor + 1 } };
  }));
}, X = globalThis && globalThis.__assign || function() {
  return X = Object.assign || function(e) {
    for (var t, r = 1, n = arguments.length; r < n; r++) {
      t = arguments[r];
      for (var a in t)
        Object.prototype.hasOwnProperty.call(t, a) && (e[a] = t[a]);
    }
    return e;
  }, X.apply(this, arguments);
}, dr = function(e) {
  return function(t) {
    return Ge(e, t, t);
  };
}, Hy = function() {
  return function(e) {
    return He(e);
  };
}, zy = function(e) {
  return function() {
    return He(e);
  };
}, j = function(e) {
  return g(Wy(Fy()), ce(function(t) {
    var r = t[0], n = t[1];
    return e(r) ? ye(r) : zy(n);
  }));
}, E = function(e, t) {
  return function(r) {
    return g(e(r), jt(function(n) {
      return My(n, [t]);
    }));
  };
}, Fy = function() {
  return function(e) {
    return g(Gy(e), cr(function() {
      return He(e);
    }, function(t) {
      var r = t.value, n = t.next;
      return Ge(r, n, e);
    }));
  };
}, Ky = function(e, t) {
  return function(r) {
    return g(e(r), dn(function(n) {
      return g(t(n.value)(n.next), dn(function(a) {
        return Ge(a.value, a.next, r);
      }));
    }));
  };
}, kt = function(e, t) {
  return function(r) {
    var n = e(r);
    return Ly(n) || n.left.fatal ? n : g(t()(r), jt(function(a) {
      return Dy(n.left, a);
    }));
  };
}, Wy = function(e) {
  return function(t) {
    return g(e(t), Be(function(r) {
      return X(X({}, r), { value: [r.value, t] });
    }));
  };
}, va = function(e) {
  return le(function() {
    return ye(e.empty);
  });
}, fr = function() {
  return E(function(e) {
    return By(e) ? Ge(void 0, e, e) : He(e);
  }, "end of file");
}, Qy = function(e) {
  return g(pr(e), le(function() {
    return ye([]);
  }));
}, pr = function(e) {
  return g(e, ce(function(t) {
    return vr(Ay(t), function(r) {
      return g(e, O(function(n) {
        return te(Sy(n)(r));
      }), le(function() {
        return ye(B(r));
      }));
    });
  }));
}, Jy = function(e, t) {
  return function(r) {
    return g(e, ce(function() {
      return r;
    }), yr(function() {
      return t;
    }));
  };
}, ya = function(e) {
  return Jy(e, e);
}, Yy = function(e) {
  return Qy(j(ee(e)));
}, hr = function(e, t) {
  return g(t, O(function() {
    return $y;
  }), le(function() {
    return Xy(e, t);
  }));
}, Xy = function(e, t) {
  return g(e, ce(function(r) {
    return vr(es(r), function(n) {
      return g(t, O(function() {
        return B(n);
      }), le(function() {
        return g(e, O(function(a) {
          return te(my(a)(n));
        }));
      }));
    });
  }));
}, ze = function(e, t) {
  return function(r) {
    return g(e(r), Be(function(n) {
      return X(X({}, n), { value: t(n.value) });
    }));
  };
}, ga = function(e, t) {
  return Ct(e, function(r) {
    return ze(t, r);
  });
}, Ct = function(e, t) {
  return Ky(e, t);
}, vr = function(e, t) {
  var r = function(n) {
    return function(a) {
      return ve(a.value) ? te({ value: a.value.left, stream: a.next }) : B(Ge(a.value.right, a.next, n));
    };
  };
  return function(n) {
    return Ny({ value: e, stream: n }, function(a) {
      var s = t(a.value)(a.stream);
      return ve(s) ? B(He(a.stream, s.left.expected, s.left.fatal)) : r(n)(s.right);
    });
  };
}, eg = function(e, t) {
  return kt(e, t);
}, O = function(e) {
  return function(t) {
    return ze(t, e);
  };
}, ye = dr, ce = function(e) {
  return function(t) {
    return Ct(t, e);
  };
}, yr = function(e) {
  return function(t) {
    return Ct(t, function(r) {
      return ze(e(r), function() {
        return r;
      });
    });
  };
}, le = function(e) {
  return function(t) {
    return eg(t, e);
  };
}, tg = "Parser", rg = function(e) {
  return {
    concat: function(t, r) {
      return ga(ze(t, function(n) {
        return function(a) {
          return e.concat(n, a);
        };
      }), r);
    }
  };
}, ng = function(e) {
  return X(X({}, rg(e)), { empty: dr(e.empty) });
}, ag = {
  URI: tg,
  map: ze,
  ap: ga,
  chain: Ct,
  chainRec: vr
}, ma = function(e, t, r) {
  var n;
  return Object.assign({}, e, (n = {}, n[t] = r, n));
}, _a = function(e) {
  return function(t) {
    return g(t, O(function(r) {
      return ma({}, e, r);
    }));
  };
}, Ft = function(e, t) {
  return function(r) {
    return g(r, ce(function(n) {
      return g(t(n), O(function(a) {
        return ma(n, e, a);
      }));
    }));
  };
}, sg = function(e) {
  return Ka(e)(e.empty);
};
Wa.concat;
var ig = va(Qt), ge = function(e) {
  return E(j(function(t) {
    return t === e;
  }), '"'.concat(e, '"'));
}, og = function(e) {
  return E(j(function(t) {
    return t !== e;
  }), 'anything but "'.concat(e, '"'));
}, gr = function(e) {
  return ig(Fe(e));
}, Fe = function(e) {
  return g(pr(e), O(function(t) {
    return t.join("");
  }));
}, mr = function(e) {
  return "0123456789".indexOf(e) !== -1;
}, Kt = E(j(mr), "a digit"), ug = /^\s$/, ba = function(e) {
  return ug.test(e);
}, Ta = E(j(ba), "a whitespace"), cg = function(e) {
  return e === "_";
}, Ke = function(e) {
  return /[a-z]/.test(e.toLowerCase());
}, $a = function(e) {
  return Ke(e) || mr(e) || cg(e);
};
E(j($a), "a word character");
E(j(Ke), "a letter");
var lg = function(e) {
  return e.toLowerCase() !== e.toUpperCase();
};
E(j(lg), "an unicode letter");
var wa = function(e) {
  return Ke(e) && e === e.toUpperCase();
};
E(j(wa), "an upper case letter");
var xa = function(e) {
  return Ke(e) && e === e.toLowerCase();
};
E(j(xa), "a lower case letter");
E(j(ee(mr)), "a non-digit");
var Aa = E(j(ee(ba)), "a non-whitespace character");
E(j(ee($a)), "a non-word character");
E(j(ee(Ke)), "a non-letter character");
E(j(ee(wa)), "anything but an upper case letter");
E(j(ee(xa)), "anything but a lower case letter");
var Sa = function(e) {
  return E(ag.chainRec(e, function(t) {
    return g(pg(0, t), cr(function() {
      return ye(B(e));
    }, function(r) {
      return g(ge(r), ce(function() {
        return ye(te(t.slice(1)));
      }));
    }));
  }), JSON.stringify(e));
}, Wt = sg(ng(Qt)), Me = va(Qt), dg = function(e) {
  return Me(fg(e));
}, fg = function(e) {
  return g(pr(e), O(function(t) {
    return t.join("");
  }));
}, pg = function(e, t) {
  return e >= 0 && e < t.length ? qe(t.charAt(e)) : Et;
}, hg = gr(Ta);
Fe(Ta);
gr(Aa);
Fe(Aa);
var vg = function(e) {
  var t = +e;
  return isNaN(t) || e === "" ? Et : qe(t);
};
E(g(Wt([Me(ge("-")), Fe(Kt)]), O(function(e) {
  return +e;
})), "an integer");
E(g(Wt([Me(ge("-")), gr(Kt), Me(Wt([ge("."), Fe(Kt)]))]), ce(function(e) {
  return g(vg(e), cr(function() {
    return Hy();
  }, dr));
})), "a float");
var yg = ya(ge('"'))(dg(kt(Sa('\\"'), function() {
  return og('"');
})));
function Oa(e) {
  return function(t) {
    return t(Uy(e.split("")));
  };
}
const fn = (e, t) => (r) => ({
  ...r,
  [e]: t(r[e])
}), gg = ["#", ":"], me = ya(hg), _r = (e) => (t) => t.join(e), br = (e) => g(
  Yy((t) => e.includes(t)),
  O(_r(""))
), mg = W(
  br,
  yr(() => j(() => !0))
), Ea = g(
  yg,
  O((e) => JSON.parse(`"${e}"`))
), _g = g(
  me(Ea),
  le(
    () => g(
      br([":", `
`]),
      O(mn)
    )
  )
), bg = g(
  me(Ea),
  le(
    () => g(
      br([`
`]),
      O(mn)
    )
  )
), ja = g(
  Me(Sa("#")),
  O(ee(_n))
), ka = g(
  me(ja),
  _a("commented"),
  Ft("key", () => me(_g)),
  yr(() => ge(":")),
  Ft("value", () => bg)
), Tg = g(
  me(ja),
  _a("commented"),
  Ft("key", () => kt(
    mg([`
`]),
    () => g(
      hr(j((e) => !0), fr()),
      O(W(
        fa,
        _r("")
      ))
    )
  )),
  O(W(
    pa(({ key: e }) => !_n(e))
  ))
), $g = g(
  hr(me(ka), fr())
), wg = g(
  hr(
    kt(
      g(ka, O(qe)),
      () => g(
        Tg,
        O(W(
          zt((e) => ({ ...e, value: "" }))
        ))
      )
    ),
    fr()
  ),
  O(W(
    Ty(W(
      pa(Cy),
      zt((e) => e.value)
    ))
  ))
), xg = (e) => {
  if (gg.some((n) => e.includes(n)))
    return !0;
  const t = JSON.stringify(e);
  return t.substring(1, t.length - 1).trim() !== e;
}, pn = (e) => xg(e) ? JSON.stringify(e) : e, Lg = (e) => g(
  e,
  Ey(
    W(
      fn("key", pn),
      fn("value", pn),
      ({ key: t, value: r, active: n }) => n ? `${t}: ${r}` : `# ${t}: ${r}`
    )
  ),
  _r(`
`)
), Ag = (e) => g(
  wg,
  Oa(e),
  jt((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  Be(
    ({ value: t }) => g(
      t,
      da(
        ({ key: r, value: n, commented: a }) => ({
          active: !a,
          key: r,
          value: n
        })
      )
    )
  )
), Zg = (e) => g(
  $g,
  Oa(e),
  jt((t) => ({
    message: `Expected ${t.expected.map((r) => `'${r}'`).join(", ")}`,
    expected: t.expected,
    pos: t.input.cursor
  })),
  Be(
    ({ value: t }) => g(
      t,
      da(
        ({ key: r, value: n, commented: a }) => ({
          active: !a,
          key: r,
          value: n
        })
      )
    )
  )
), Mg = W(
  Ag,
  Be(fa),
  lr(() => [])
), Sg = u.object({
  id: u.optional(u.string()),
  name: u.string(),
  variables: u.array(
    u.object({
      key: u.string(),
      value: u.string()
    })
  )
}), hn = Ve({
  initial: !0,
  schema: Sg
}), Dg = or({
  latestVersion: 0,
  versionMap: {
    0: hn
  },
  getVersion(e) {
    return hn.schema.safeParse(e).success ? 0 : null;
  }
}), yt = /<<([^>]*)>>/g, gt = 10, Ca = "ENV_EXPAND_LOOP";
function Og(e, t) {
  let r = e, n = 0;
  for (; r.match(yt) != null && n <= gt; )
    r = r.replace(yt, (a) => {
      const s = t.find(
        (i) => i.key === a.replace(/[<>]/g, "")
      );
      return s ? s.value : a;
    }), n++;
  return n > gt ? te(Ca) : B(r);
}
const Vg = (e, t) => g(
  Og(e, t),
  lr(() => e)
);
function Eg(e, t) {
  if (!t || !e)
    return B(e);
  let r = e, n = 0;
  for (; r.match(yt) != null && n <= gt; )
    r = decodeURI(encodeURI(r)).replace(
      yt,
      (a, s) => {
        var i;
        return ((i = t.find((o) => o.key === s)) == null ? void 0 : i.value) || "";
      }
    ), n++;
  return n > gt ? te(Ca) : B(r);
}
const Ug = (e, t) => g(
  Eg(e, t),
  lr(() => e)
);
export {
  Dg as Environment,
  ea as FormDataKeyValue,
  ua as GQLHeader,
  ur as GQL_REQ_SCHEMA_VERSION,
  oy as HoppGQLAuth,
  iy as HoppGQLAuthAPIKey,
  ny as HoppGQLAuthBasic,
  ay as HoppGQLAuthBearer,
  ry as HoppGQLAuthNone,
  sy as HoppGQLAuthOAuth2,
  dy as HoppGQLRequest,
  ra as HoppRESTAuth,
  Ph as HoppRESTAuthAPIKey,
  Ih as HoppRESTAuthBasic,
  Rh as HoppRESTAuthBearer,
  Ch as HoppRESTAuthNone,
  Nh as HoppRESTAuthOAuth2,
  ta as HoppRESTReqBody,
  jg as HoppRESTReqBodyFormData,
  oa as HoppRESTRequest,
  Jv as RESTReqSchemaVersion,
  kg as ValidContentTypesList,
  py as getDefaultGQLRequest,
  Yv as getDefaultRESTRequest,
  Cg as isEqualHoppRESTRequest,
  Ng as isHoppRESTRequest,
  Kv as knownContentTypes,
  ca as makeCollection,
  Pg as makeGQLRequest,
  Rg as makeRESTRequest,
  Vg as parseBodyEnvVariables,
  Og as parseBodyEnvVariablesE,
  Mg as parseRawKeyValueEntries,
  Ag as parseRawKeyValueEntriesE,
  Ug as parseTemplateString,
  Eg as parseTemplateStringE,
  Lg as rawKeyValueEntriesToString,
  Ig as safelyExtractRESTRequest,
  Zg as strictParseRawKeyValueEntriesE,
  hy as translateToGQLRequest,
  gy as translateToNewGQLCollection,
  yy as translateToNewRESTCollection,
  Xv as translateToNewRequest
};
